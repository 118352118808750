import React, { useEffect, useState } from "react";
import "./Css folder/Totale.css";

function Totale(props) {
  const [paginecolori, setPagineColori] = useState(0);
  const [paginebn, setPagineBn] = useState(0);
  const [prevpaginecolori, setPrevPagineColori] = useState(0);
  const [prevpaginebn, setPrevPagineBn] = useState(0);
  const [prev, setPrev] = useState(false);

  useEffect(() => {
    setPagineBn(window.localStorage.getItem("paginebn"));
    setPagineColori(window.localStorage.getItem("paginecolori"));

    if (props.prev != null) {
      setPrev(true);
      setPrevPagineBn(props.prevpaginebn);
      setPrevPagineColori(props.prevpaginecolori);
    }
  }, [
    props.updatecolor,
    props.handlecolorepagine,
    props.prevpaginebn,
    props.prevpaginecolori,
  ]);

  useEffect(() => {}, [props.update]);

  return (
    <div id="tot" className="totcont">
      <div className="title-large center"> PREVENTIVO </div>
      <div className="ordini">
        {props.copie.length > 0 && props.showcopie ? (
          props.copie.map((copia, i) => (
            <li key={i} className="pages">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div>{copia.value}x </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {copia.type.rilegatura +
                      " " +
                      copia.type.materiale +
                      " " +
                      copia.type.copertina +
                      "     "}
                  </div>
                  <div>{" " + copia.prezzo + "€"}</div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <></>
        )}
        {props.showpages && (
          <>
            <div className="pages small"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Pagine in B/N:</div> {paginebn}</div>
            <div className="pages small"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Pagine a Colori:</div> {paginecolori}</div>
            
          </>
        )}
        {prev && (
          <>
            <div className="pages"> <div style={{fontWeight:"bold", marginRight:"5px"}}>Rilegatura: </div>  {props.rilegatura} <div style={{marginLeft:"auto"}}>{props.cartoncino && props.pricerilegatura}{props.cartoncino && "€"} </div> </div>
            {!props.cartoncino && <div className="pages"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Materiale:</div> {props.materiale}<div style={{marginLeft:"auto"}}>{props.pricemateriale}€ </div> </div>} 
            <div className="pages"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Pagine in B/N:</div> {prevpaginebn} <div style={{marginLeft:"auto"}}>{isNaN(props.prezzopaginebn) ? 0 : props.prezzopaginebn}€ </div> </div>
            <div className="pages"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Pagine a Colori:</div> {prevpaginecolori} <div style={{marginLeft:"auto"}}>{isNaN(props.prezzopaginecolori) ? 0 : props.prezzopaginecolori}€ </div> </div>
            <div className="pages"> <div style={{fontWeight:"bold",marginRight:"5px"}}>Spese di spedizione:</div><div style={{marginLeft:"auto"}}>{isNaN(props.pricespedizione) ? 0 : props.pricespedizione}€ </div> </div>
          </>
        )}
      </div>
      <div className="totprice">
        Totale <div>{props.tot} € </div>
      </div>
    </div>
  );
}

export default Totale;
