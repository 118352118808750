import React from 'react'
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./Css folder/Rilegature.css";
import API from "../API";
import Footer from "./Footer";

function Materiali() {

  const [selectedmaterial, setSelectedMaterial] = useState("Ecopelle");
  const [colorcopertina, setColorCopertina] = useState("Arancione");
  const [colorcopertina_src, setColorCopertinaSrc] = useState("arancione.png");
  const [colorfrontespizio, setColorFrontespizio] = useState("Argento");
  const [colorfrontespizio_src, setColorFrontespizioSrc] = useState("argento.png");
  const [cartoncino,setCartoncino] = useState(false);
  const [rilegatura, setRilegatura] = useState("Rigida");
  const [materialiarray, setMaterialiArray] = useState([]);
  const [materialiePrezzi,setMaterialiePrezzi] = useState([]);
  const [materiali_rigida,setMaterialiRigida] = useState([]);
  const [scritte,setScritte] = useState([]);
  const [go, setGo] = useState(false);

  var prezzocartoncino = 5;
  var prezzomorbida = 12;

  const testorigida = 
  " E’ la rilegatura classica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartone rigido rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testomorbida = "E’ la rilegatura elegante, ma più economica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartoncino rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testocartoncino = "E’ la rilegatura più economica utilizzata per le Tesi di Laurea. La copertina è la fotocopia del frontespizio su un cartoncino di colore Rosso, Granata, Blu o Verde. Non vi è alcuna incisione. puoi trovare degli esempi.";


  const updateValueRilegatura = ({ target }) => {
    handleRilegatura(target.value);  
  };

  const updateValue = ({ target }) => {
    
    setSelectedMaterial(target.value);

    let copertina = materialiarray.find(function (element) {
       return element.materiale == target.value;
    })

    setColorCopertinaSrc(copertina.immagine);
    
  };

  function handleFrontespizio(nome,immagine){
    setColorFrontespizio(nome);
    setColorFrontespizioSrc(immagine);
  }

  function handleCopertina(nome,immagine){

    setColorCopertina(nome);
    setColorCopertinaSrc(immagine);
  
    }

  const handleRilegatura = (_rilegatura) => {

    if(_rilegatura == "Cartoncino") {
    setSelectedMaterial("Ecopelle");
    setColorCopertina("Blu chiaro");
    setColorCopertinaSrc("../../miniature/Ecopelle/blu_chiaro.png");
    setCartoncino(true);
    setColorFrontespizio("Nero");
    setColorFrontespizioSrc("../../scritteCopertina/nero.png");}

    else if(rilegatura == "Cartoncino"){
      setCartoncino(false);
      let copertina = materialiarray.find(function (element) {
        return element.materiale == "Ecopelle";
     })
     
     setColorCopertina(copertina.nome);
     setColorCopertinaSrc(copertina.immagine);
    }

    setRilegatura(_rilegatura);
  }



  useEffect(() => {
   

    API.GetAllMaterials().then((allmaterials) => {
      setMaterialiArray(materialiarray => [...materialiarray, ...allmaterials]); 

      API.GetAllScritte().then((allscritte) => {
        setScritte(allscritte);
        setGo(true);

        API.GetPrezzi().then((data) => {

          let array = data.split(/\r?\n/);
          let materiale_prezzi = array.map((el) => {
    
            let obj = {"materiale":"" ,"prezzo":""};
            let splitted = el.split(" ");
    
            obj.materiale = splitted[0];
            obj.prezzo = splitted[1];
    
            return obj;
          })
          setMaterialiePrezzi(materiale_prezzi);
          const materialiRigida = materiale_prezzi.filter((el) => el.materiale !== "Tela" && el.materiale !== "Opera" && el.materiale !== "Quarzo" && el.materiale !== "Algora");
          setMaterialiRigida(materialiRigida);
      })
    
      })

  }
  )},[]);

  return (
    <div style={{height:"100%", width:"100%"}}>
        <div style={{height:"fit-content", width:"100%"}}>
            <Container fluid>
                <div className="">
                <div className="title">
                <div className="customtitle"> I nostri materiali  </div>
                </div>            
                <div className="cont_materiali mt-4">
                    <div className="Secondpagecontainer">
                        <div>
                        <div className='cont_nostri_materiali'>
                        <div className='cont_nostri_materiali2'>
                            <div className="rilegature-item tiporilegatura">
                            <div className="itemtitle"> Scegli la tipologia di rilegatura </div>
                            <div className="divselect">
                            <select
                                className="formselect"
                                label="Default select example"
                                onChange={updateValueRilegatura}
                            >
                                <option value={"Rigida"}>Rigida (a partire da 18€)</option>
                                <option value={"Morbida"}>Morbida ({prezzomorbida}€)</option>
                                <option value={"Cartoncino"}>Cartoncino ({prezzocartoncino}€)</option>
                            </select>
                            </div>
                            <div style={{ width: "80%",paddingTop: "10px", fontSize:"14px"}}>
                        {rilegatura == "Rigida" ? testorigida : rilegatura == "Morbida" ? testomorbida : testocartoncino}
                      </div>
                            </div>
                          

                            {rilegatura == "Rigida" && <div className="rilegature-item tipomateriale">
                            <div className="itemtitle"> Scegli la tipologia di materiale </div>
                            <div className="divselect">
                            <select
                                className="formselect"
                                label="Default select example"
                                onChange={updateValue}
                            >
                                {materialiePrezzi.map((element,i) => 
                                <option defaultValue value={element.materiale}>
                                {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo}€`}
                                </option>)}
                            </select>
                            </div>
                            </div>
                            }
                            {rilegatura == "Morbida" && <div className="rilegature-item tipomateriale">
                            <div className="itemtitle"> Scegli la tipologia di materiale </div>
                            <div className="divselect">
                            <select
                                className="formselect"
                                label="Default select example"
                                onChange={updateValue}
                            >
                                {materiali_rigida.map((element,i) => 
                                <option defaultValue value={element.materiale}>
                                {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo}€`}
                                </option>)}
                            </select>
                            </div>
                            </div>
                            }
                            <div className="rilegature-item colorefrontespizio">
                            <div className="itemtitle">
                            Scegli il colore di stampa del frontespizio{" "}
                            </div>
                            <div className="divcolorifrontespizio">
                            {go && rilegatura!="Cartoncino" ?
                            scritte.map((element, i) => (
                                <div key={i} className="divcolorecopertina">
                                    <div className="colorecopertina">
                                    {" "}
                                    <img
                                        className={
                                        colorfrontespizio == element.nome
                                            ? "imgcolori selected"
                                            : "imgcolori"
                                        }
                                        onClick={() =>
                                        handleFrontespizio(element.nome,element.immagine)
                                        }
                                        src={
                                        "../../scritte/" + element.immagine
                                        }
                                    ></img>{" "}
                                    </div>
                                    <div style={{alignSelf: "center"}}> {element.nome} </div>
                                </div>
                                )) : <div className="divcolorecopertina">
                                <div className="colorecopertina">
                                {" "}
                                <img
                                    className={
                                    "imgcolori selected"
                                    }
                                    src={
                                    "../../scritte/nero.png"
                                    }
                                ></img>{" "}
                                </div>
                                <div style={{alignSelf: "center"}}> Nero </div>
                            </div>
                                }
                            </div>
                            </div>
                            <div className="rilegature-item coloremateriale noborderbottom">
                            <div className="itemtitle">
                            Scegli il colore della copertina
                            </div>
                            <div className="divcoloricopertina">
                            {!cartoncino ?
                                (materialiarray.map(
                                (element, i) =>
                                    element.materiale == selectedmaterial && (
                                    <div key={i} className="divcolorecopertina">
                                        <div className="colorecopertina">
                                        {" "}
                                        <img
                                            className={
                                            colorcopertina === element.nome
                                                ? "imgcolori selected"
                                                : "imgcolori"
                                            }
                                            onClick={() => handleCopertina(element.nome,element.immagine)}
                                            src={
                                            "../../materiali/" +
                                            element.materiale +
                                            "/" +
                                            element.immagine
                                            }
                                        ></img>{" "}
                                        </div>
                                        <div style={{alignSelf: "center"}}> {element.nome} </div>
                                    </div>
                                    )
                                )):
                                (materialiarray.map(
                                (element, i) =>
                                    element.materiale == selectedmaterial && (element.nome == "Rosso" || element.nome == "Blu chiaro" || element.nome == "Granata" || element.nome == "Verde marmorizzato") && (
                                    <div key={i} className="divcolorecopertina">
                                        <div className="colorecopertina">
                                        {" "}
                                        <img
                                            className={
                                            colorcopertina === element.nome
                                                ? "imgcolori selected"
                                                : "imgcolori"
                                            }
                                            onClick={() => handleCopertina(element.nome,element.immagine)}
                                            src={
                                            "../../materiali/" +
                                            element.materiale +
                                            "/" +
                                            element.immagine
                                            }
                                        ></img>{" "}
                                        </div>
                                        <div style={{alignSelf: "center"}}> {element.nome} </div>
                                    </div>
                                    )
                                ))}
                            </div>
                        </div>
                        </div>
                        <div className="divcopertina materiali ml-none">
                                <img
                                    id="imgcopertina"
                                    className="imganteprima"
                                    src={"../../miniature/" + selectedmaterial + "/" + colorcopertina_src} 
                                    
                                />

                                <img
                                id="imgscritta"
                                className="imgscritta"
                                src={"../../scritteCopertina/" + colorfrontespizio_src}
                               
                                />
                        </div>
                        </div>        
                        </div>  
                    </div>
                    
                    
                </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>
    </div>                                  
  )
}

export default Materiali