import React from "react";
import { Page } from "react-pdf";
import { useState, useEffect } from "react";
import "./Css folder/Pagina.css";
function Pagina(props) {
  const [color, setColor] = useState(true);
  const [showdivcolor, setShowDivColor] = useState(props.showdivcolor);
  const [loading, setLoading] = useState(false);

  function handleloading() {
    setLoading(true);
  }

  function handlefinishloading() {
    props.finishloading();
    setLoading(false);
  }

  useEffect(() => {
    if (props.AllColor) {
      setColor(true);
      props.handleallpaginecolore();
    } else if (props.AllBn) {
      setColor(false);
      props.handleallpaginebn();
    }
  }, [props.clicked]);

  return (
    <div className={showdivcolor ? "Pagediv" : "Pagedivinactive"}>
      <div
        className={color ? "circle" : "circlebn"}
        id={color ? "circle" : "circlebn"}
      ></div>
      <Page
        className={color ? "page" : "pagebn"}
        id={color ? "page" : "pagebn"}
        pageNumber={props.i + 1}
        key={props.i}
        loading={handleloading}
        onRenderSuccess={handlefinishloading}
      />
    </div>
  );
}

export default Pagina;
