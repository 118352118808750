import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useLocation, Link } from 'react-router-dom'
import Footer from './Footer';

function ServizioRef() {

  const location = useLocation();  
  const state = location.state;
  const {id_servizio} = useParams(); 

  return (
    <div className="h-100 w-100">
        <nav className="m-5 mb-0"aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="text-dark text-decoration-none" href="/">Home</a></li>
            <li className="breadcrumb-item"><a className="text-dark text-decoration-none" href="/servizi">Altri servizi</a></li>
            <li className="breadcrumb-item active" aria-current="page">{id_servizio}</li>
        </ol>
        </nav>
        <Container fluid className="d-flex flex-column h-100 w-100">
            <Row className="mx-5 mt-2 card_service">
            <div className="card d-flex flex-row flex-wrap-mobile">
                <div className="card_servizio">
                <img className="card-img-to" src={state.immagine} alt="Card image cap" />
                </div>
                <div className="">
                <div className="card-body">
                    <div className="card-title text-xl semibold">{id_servizio}</div>
                    <p className="card-text card_border pb-4">
                        {state.description}
                    </p>
                    <Link to="/help" className ="btninput linkactive w-30 px-5">Contattaci</Link>
                </div>
                </div>
            </div>
            </Row>
            <Row className="mt-auto m-0">
                <Footer/>
            </Row>
        </Container>
    </div>    
  )
}

export default ServizioRef