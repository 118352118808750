import React from "react";
import OrdineMail from "./OrdineMail";

function TableMail(props) {

  return (
    <div className="container">
      <p><b>Da:</b>{" "}{props.dati.Nome}{" "}{props.dati.Cognome}</p> 
      <p><b>Recapito telefonico:</b>{props.dati.Telefono}</p>
      <p><b>Email:</b>{props.dati.Email}</p>
      <br/>

      <div style={{ marginBottom: "10px" }}>
        {" "}
        <b>Spedizione a casa:</b> {props.consegna ? "Sì" : "No"}{" "}
      </div>
      {props.consegna && (
        <div>
          <div className="title-large "> Indirizzo di spedizione</div>
          <div>
            <div>
              {" "}
              {props.dati.Nome} {props.dati.Cognome}{" "}
            </div>{" "}
            <p>
              {" "}
              {props.dati.Indirizzo} {props.dati.Ncivico} , {props.dati.Città},{" "}
              {props.dati.Provincia}, {props.dati.CAP}, {props.dati.Stato}
            </p>
          </div>
          <div>Spedizione scelta: {props.corriere}</div>
        </div>
      )}
        <br/>
            {props.dati.Note && (
              <>
                <div className="title2"> Note </div>
                <p> {props.dati.Note} </p>
              </>
            )}
      <div className="table">
        {props.ordini.map((ordine, i) => (
          <>
            {" "}
            <OrdineMail key={i} ordine={ordine} /> <br />
          </>
        ))}
      </div>
      <br/>
      <div>
        <p>Numero pagine in B/N: {props.arraycolor.filter((el=> !el.color)).reduce((acc,curr)=> acc + 1, 0)}</p>
        <p>Numero pagine a colori: {props.arraycolor.filter((el=> el.color)).reduce((acc,curr)=> acc + 1, 0)}</p>
        <p>Elenco pagine a colori: </p>
         <div>{props.colorpagesarray}</div>
      </div>
    </div>
  );
}

export default TableMail;
