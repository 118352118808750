import React, { useRef } from "react";
import "./Home.css";
import "./HomePage1.css";
import HomePage2 from "./HomePage2";
import HomePage from "./HomePage";
import Avvisi from "../Avvisi";

function Home() {
  const hp1Ref = useRef();
  const hp2Ref = useRef();


  return (
    <div className="container-fluid home-color">
      <Avvisi/>
      <HomePage />
      <HomePage2 ref={hp2Ref}></HomePage2>
    </div>
  );
}

export default Home;
