import React from "react";
import "./Css folder/Preventivo.css";
import Dropdownmenu from "./Dropdownmenu";
import Upload from "./Upload";
import Footer from "./Footer";
import "./Css folder/Stampa.css";

function Stampa(props) {
  return (
    <div className="containerstampa">
      <div style={{ height:"100%", width:"100%"}}>
    
      <div className="containerrow">
        <div className="el1">
          <Dropdownmenu selected={"document"} clickable={props.clickable} />
        </div>
        <div className="el2 upload">
          <Upload
            handlepath={props.handlepath}
            handlepathfrontespizio={props.handlepathfrontespizio}
            addvisitableroute={props.addvisitableroute}
          ></Upload>
        </div>
      </div>
      <Footer/>
      </div>
    
    </div>  );
}

export default Stampa;
