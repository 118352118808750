async function RetrieveLogo() {
  const response = await fetch("/copertine");
  const res = await response.json();

  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function UploadEditFile(pdfBytes) {
  const response = await fetch("/uploadeditfile", {
    method: "POST",
    body: pdfBytes,
  });

  const res = await response.json();

  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function UploadFile(formData) {
  const response = await fetch("/upload", {
    method: "POST",
    body: formData,
  });

  const res = await response.json();

  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function SendEmail(to, subject, text, html, file) {
  const f = { to: to, subject: subject, text: text, html: html, file: file };
  const response = await fetch("/sendemail", {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(f),
  });

  if (response.ok) {
    return response;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function SendClientEmail(to, subject, text, html) {
  const f = { to: to, subject: subject, text: text, html: html };
  const response = await fetch("/sendclientemail", {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(f),
  });

  if (response.ok) {
    return response;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function SendContactEmail(formData) {
  const response = await fetch("/sendcontactemail", {
    method: "POST",
    body: formData,
  });

  if (response.ok) {
  return response;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function DeleteOldFile(oldfile) {
  const response = await fetch("/delete/" + oldfile);
  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function CheckFile(file) {
  const response = await fetch("/fileexist/" + file);

  if (response.ok) {
    return true;
  } else {
    return false;
  }
}

async function SaveFileFromUri(data) {
  const response = await fetch("/savefilefromuri", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  });

  const res = await response.json();

  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetColorsByMaterial(material) {
  const response = await fetch("/material/" + material, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetMaterials() {
  const response = await fetch("/materials/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetAllMaterials() {
  const response = await fetch("/allmaterials/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetAllMiniature() {
  const response = await fetch("/allminiature/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetAllScritte() {
  const response = await fetch("/allscritte/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetPrezzi() {
  const response = await fetch("/allprezzi", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetTestoAvvisi() {
  const response = await fetch("/testoavvisi", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

async function GetOrari() {
  const response = await fetch("/orari", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}

const StripePayment = async (price) => {
  const url = '/create-checkout-session';
  const unitAmount = price; // Dynamically calculated unit_amount value

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ unit_amount: unitAmount }), // Include the dynamic unit_amount value in the request body
    });

    const body = await response.json()
    window.location.href = body.url

    // Rest of the code to handle the response
  } catch (error) {
    console.log('Error:', error.message);
  }
};

async function CheckoutStripe(price) {
  const f = { price: price};
  const response = await fetch("/create-checkout-session", {
    method: "POST",
    headers: { 
    "Content-type": "application/json"},
    body: JSON.stringify(f),
  });

  const res = await response.json();

  if (response.ok) {
    return res;
  } else {
    const message = `Errore: ${response.status}`;
    throw new Error(message);
  }
}






const API = {
  RetrieveLogo,
  SendEmail,
  SendContactEmail,
  SendClientEmail,
  UploadFile,
  DeleteOldFile,
  UploadEditFile,
  SaveFileFromUri,
  CheckFile,
  GetColorsByMaterial,
  GetMaterials,
  GetAllMaterials,
  GetAllMiniature,
  GetAllScritte,
  GetPrezzi,
  GetTestoAvvisi,
  CheckoutStripe,
  StripePayment,
  GetOrari
};
export default API;
