import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Css folder/Dati.css";
import { Row } from "react-bootstrap";
import Dropdownmenu from "./Dropdownmenu";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import Footer from "./Footer";
import Map from "./Map";
import API from "../API";
import TableMail from "./TableMail";
import { renderToString } from "react-dom/server";

function Dati(props) {
  const [nome, setNome] = useState("");
  const [nomeValid, setNomeValid] = useState(false);
  const [cognome, setCognome] = useState("");
  const [cognomeValid, setCognomeValid] = useState(false);
  const [nomeSpedizione, setNomeSpedizione] = useState("");
  const [nomeSpedizioneValid, setNomeSpedizioneValid] = useState(false);
  const [cognomeSpedizione, setCognomeSpedizione] = useState("");
  const [cognomeSpedizioneValid, setCognomeSpedizioneValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [telefono, setTelefono] = useState("");
  const [telefonoValid, setTelefonoValid] = useState(false);
  const [note, setNote] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [indirizzoValid, setIndirizzoValid] = useState(false);
  const [ncivico, setNcivico] = useState("");
  const [ncivicoValid, setNcivicoValid] = useState(false);
  const [città, setCittà] = useState("");
  const [cittàValid, setCittàValid] = useState(false);
  const [provincia, setProvincia] = useState("TO");
  const [provinciaValid, setProvinciaValid] = useState(false);
  const [stato, setStato] = useState("");
  const [statoValid, setStatoValid] = useState(false);
  const [CAP, setCAP] = useState("");
  const [CAPValid, setCAPValid] = useState(false);
  const [CAPerror, setCAPError] = useState("");
  const [consegna, setConsegna] = useState(true);
  const [consegnaEstero, setConsegnaEstero] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [spedizione, setSpedizione] = useState("SDA");
  const [pricespedizione, setPriceSpedizione] = useState(9);
  const [textbutton, setTextButton] = useState("Invia preventivo");
  const [formEmergenzaValid,setFormEmergenzaValid] = useState(true);

  const navigate = useNavigate();

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handlespedizione = (spedizione) => {
    switch (spedizione) {
      case "SDA":
        setSpedizione("SDA");
        setPriceSpedizione(9);
        props.updateSpedizione("SDA", 9);
        break;
      case "UPS":
        setSpedizione("UPS");
        setPriceSpedizione(12);
        props.updateSpedizione("UPS", 12);
        break;
      case "Ritiro":
        setSpedizione("Ritiro");
        setPriceSpedizione(0);
        props.updateSpedizione("Ritiro", 0);
        break;
    }
  };




  {/* FUNZIONI PER FORM PREVENTIVO */}

  

  const handlerichiesta = () => {

    let files = [];
  let ordinimail = [];

  props.ordini.forEach((e) => {
    let ordine = {
      Rilegatura: e.type.rilegatura,
      Materiale: e.type.materiale,
      Copertina: e.type.copertina,
      Scritta: e.type.scritta,
      Grammatura: e.type.grammatura,
      Impaginazione: e.type.impaginazione,
      Numero_copie: e.value,
    };
    ordinimail.push(ordine);
  });

  let tesi = window.localStorage.getItem("pathtesi");
    let frontespizio = window.localStorage.getItem("pathfrontespizio");

    if (tesi) files.push(tesi);
    if (frontespizio) files.push(frontespizio);

  let datiEmergenza = {
    Nome: nome,
    Cognome: cognome,
    Email: email,
    Telefono: telefono,
    Note: note,
  };

  let colorpagesarray = props.arraycolor.filter((pagina) => pagina.color).map((c) => c.id + 1).join(",");

  let tablemail = renderToString(
    <TableMail
      arraycolor={props.arraycolor}
      ordini={ordinimail}
      colorpagesarray={colorpagesarray}
      consegna={consegna}
      dati = {datiEmergenza}
      totale={props.totale}
      fronteretro={props.fronteretro}
      corriere={spedizione}
    />
  );

    setTextButton("Invio..");

    API.SendEmail(
      "legatoriaecoduemila@gmail.com",
      "Richiesta Emergenza 4H",
      JSON.stringify(props.ordini),
      tablemail,
      files
    )
      .then(
        setTextButton("Preventivo inviato!"),
        setFormEmergenzaValid(false))
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {

    if (consegna)
      props.updateSpedizione(spedizione, pricespedizione);
    else
      props.updateSpedizione(spedizione, 0);

    let info = JSON.parse(window.localStorage.getItem("sendinfo"));
    if (info) {
      if (info.Nome) setNome(info.Nome);
      if (info.Cognome) setCognome(info.Cognome);
      if (info.NomeSpedizione) setNomeSpedizione(info.NomeSpedizione);
      if (info.CognomeSpedizione) setCognomeSpedizione(info.CognomeSpedizione);
      if (info.Email) setEmail(info.Email);
      if (info.Telefono) setTelefono(info.Telefono);
      if (info.Indirizzo) setIndirizzo(info.Indirizzo);
      if (info.Ncivico) setNcivico(info.Ncivico);
      if (info.CAP) setCAP(info.CAP);
      if (info.Città) setCittà(info.Città);
      if (info.Provincia) setProvincia(info.Provincia);
      if (info.Stato) setStato(info.Stato);
    }

    if (props.ordini.length <= 0) {
      navigate("/print/rilegature");
    }
  }, []);

  useEffect(() => {

    validateForm();
    validateField("nome", nome);
    validateField("cognome", cognome);
    validateField("nomeSpedizione", nomeSpedizione);
    validateField("cognomeSpedizione", cognomeSpedizione);
    validateField("email", email);
    validateField("telefono", telefono);
    validateField("CAP", CAP);
    validateField("indirizzo", indirizzo);
    validateField("ncivico", ncivico);
    validateField("città", città);
    validateField("provincia", provincia);
    validateField("stato", stato);
  }, [
    nome,
    cognome,
    nomeSpedizione,
    cognomeSpedizione,
    email,
    telefono,
    CAP,
    indirizzo,
    ncivico,
    provincia,
    città,
    consegna,
    stato,
    consegnaEstero
  ]);


  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "nome":
        setNome(value);
        validateField(name, value);
        break;

      case "nomeSpedizione":
        setNomeSpedizione(value);
        validateField(name, value);
        break;

      case "note":
        setNote(value);
        break;

      case "cognome":
        setCognome(value);
        validateField(name, value);
        break;

      case "cognomeSpedizione":
        setCognomeSpedizione(value);
        validateField(name, value);
        break;

      case "email":
        setEmail(value);
        validateField(name, value);
        break;

      case "telefono":
        setTelefono(value);
        validateField(name, value);
        break;

      case "indirizzo":
        setIndirizzo(value);
        validateField(name, value);
        break;

      case "ncivico":
        setNcivico(value);
        validateField(name, value);
        break;

      case "città":
        setCittà(value);
        validateField(name, value);
        break;

      case "provincia":
        setProvincia(value);
        validateField(name, value);
        break;

      case "CAP":
        setCAP(value);
        validateField(name, value);
        break;

      case "stato":
        setStato(value);
        validateField(name, value);
        break;
    }
  };

  const handleRitiroNegozio = () => {

    setConsegna(false);
    handlespedizione("Ritiro");

  }

  const validateField = (fieldName, value) => {
    if (value.length > 0) {
      switch (fieldName) {
        case "nome":
          setNomeValid(true);
          break;

        case "cognome":
          setCognomeValid(true);
          break;

        case "nomeSpedizione":
          setNomeSpedizioneValid(true);
          break;

        case "cognomeSpedizione":
          setCognomeSpedizioneValid(true);
          break;


        case "email":
          setEmailValid(true);
          break;

        case "telefono":
          setTelefonoValid(true);
          break;

        case "ncivico":
          setNcivicoValid(true);
          break;

        case "città":
          setCittàValid(true);
          break;

        case "provincia":
          setProvinciaValid(true);
          break;

        case "indirizzo":
          setIndirizzoValid(true);
          break;

        case "CAP":
          let pattern = /\b\d{5}\b/g;
          if (pattern.test(value)) {
            setCAPValid(true);
            setCAPError("");
          } else {
            setCAPValid(false);
            setCAPError("Il CAP deve avere esattamente 5 cifre");
          }
          break;

        case "stato":
          setStatoValid(true);
          break;
      }
    } else {
      switch (fieldName) {
        case "nome":
          setNomeValid(false);
          break;

        case "cognome":
          setCognomeValid(false);
          break;

        case "nomeSpedizione":
          setNomeSpedizioneValid(false);
          break;

        case "cognomeSpedizione":
          setCognomeSpedizioneValid(false);
          break;

        case "email":
          setEmailValid(false);
          break;

        case "telefono":
          setTelefonoValid(false);

          break;

        case "indirizzo":
          setIndirizzoValid(false);
          break;

        case "ncivico":
          setNcivicoValid(false);
          break;

        case "città":
          setCittàValid(false);
          break;

        case "provincia":
          setProvinciaValid(false);
          break;

        case "CAP":
          setCAPValid(false);
          break;

        case "stato":
          setStatoValid(false);
      }
    }
  };

  const validateForm = () => {
    if (consegna) {
      if (consegnaEstero) {
        if (
          nome &&
          cognome &&
          nomeSpedizione &&
          cognomeSpedizione &&
          email &&
          telefono &&
          indirizzo &&
          stato &&
          ncivico &&
          città &&
          CAP
        ) {
          setFormValid(true);
        } else setFormValid(false);
      }
      else
        if (
          nome &&
          cognome &&
          nomeSpedizione &&
          cognomeSpedizione &&
          email &&
          telefono &&
          indirizzo &&
          ncivico &&
          città &&
          CAP
        ) {
          setFormValid(true);
        } else setFormValid(false);
    } else {
      if (nome && cognome && email && telefono) {
        setFormValid(true);
      } else setFormValid(false);
    }
  };

  const sendinfo = () => {
    if (consegna) {
      if (!consegnaEstero) {
        let dati = {
          Nome: nome,
          Cognome: cognome,
          NomeSpedizione: nomeSpedizione,
          CognomeSpedizione: cognomeSpedizione,
          Email: email,
          Telefono: telefono,
          Note: note,
          Indirizzo: indirizzo,
          Ncivico: ncivico,
          CAP: CAP,
          Città: città,
          Provincia: provincia,
          Stato: "Italia",
          Estero: "No",
          Spedizione: spedizione,
        };
        props.sendinfo(dati, true);
        window.localStorage.setItem("sendinfo", JSON.stringify(dati));
      }
      else {
        let dati = {
          Nome: nome,
          Cognome: cognome,
          NomeSpedizione: nomeSpedizione,
          CognomeSpedizione: cognomeSpedizione,
          Email: email,
          Telefono: telefono,
          Note: note,
          Indirizzo: indirizzo,
          Ncivico: ncivico,
          CAP: CAP,
          Città: città,
          Stato: stato,
          Estero: "Sì",
          Spedizione: spedizione,
        };
        props.sendinfo(dati, true);
        window.localStorage.setItem("sendinfo", JSON.stringify(dati));
      }
    } else {
      let dati = {
        Nome: nome,
        Cognome: cognome,
        Email: email,
        Telefono: telefono,
        Note: note,
      };
      props.sendinfo(dati, false);
      window.localStorage.setItem("sendinfo", JSON.stringify(dati));
    }
  };

  const updateValue = ({ target }) => {
    setProvincia(target.value);
  };

  const handleConsegnaEstero = (estero) => {

    setConsegnaEstero(estero);
    if (!estero)
      setProvincia("TO");
    else
      setStato("");
  }
  return (
    <div className="containerstampa">
      <div className="containerrow">
        <div className="el1">
          {" "}
          <Dropdownmenu selected="dati" clickable={props.clickable} />{" "}
        </div>
        <div className="divcontcolore">
          <div className="containerconfig">
            <div className="el2 dati">
              <Row>
                <div className="title">4. Inserisci i dati</div>
              </Row>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="title2"> Dati personali </div>
                <div className="flexible">
                  <div className="formfield">
                    <br />
                    <input
                      className="forminput"
                      name="nome"
                      type="text"
                      value={nome}
                      onChange={(e) => handleUserInput(e)}
                      required
                    />
                    <br />
                    <label
                      className={
                        nomeValid ? "form__label iscomplete" : "form__label"}
                    >
                      {" "}
                      Nome *{" "}
                    </label>
                  </div>
                  <div className="formfield">
                    <br />
                    <input
                      className="forminput"
                      name="cognome"
                      type="text"
                      value={cognome}
                      onChange={(e) => handleUserInput(e)}
                      required
                    />
                    <br />
                    <label
                      className={
                        cognomeValid ? "form__label iscomplete" : "form__label"
                      }
                    >
                      {" "}
                      Cognome *{" "}
                    </label>
                  </div>
                </div>
                <div className="flexible">
                  <div className="formfield">
                    <br />
                    <input
                      className="forminput"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => handleUserInput(e)}
                      required
                    />
                    <br />
                    <label
                      className={
                        emailValid ? "form__label iscomplete" : "form__label"
                      }
                    >
                      {" "}
                      Email *{" "}
                    </label>
                  </div>
                  <div className="formfield">
                    <br />
                    <input
                      className="forminput"
                      name="telefono"
                      type="text"
                      value={telefono}
                      onChange={(e) => handleUserInput(e)}
                      required
                    />
                    <br />
                    <label
                      className={
                        telefonoValid ? "form__label iscomplete" : "form__label"
                      }
                    >
                      {" "}
                      Telefono *{" "}
                    </label>
                  </div>
                </div>
                <div className="formfield note">
                  <label> Note aggiuntive </label>
                  <br />
                  <textarea
                    className="forminput2"
                    placeholder="Inserisci note"
                    id="note"
                    name="note"
                    rows="4"
                    cols="50"
                    value={note}
                    onChange={(e) => handleUserInput(e)}
                  ></textarea>
                  <br />
                </div>
                <div className="formfield flexible flexcenter">
                  <div className="consegna">
                    <div
                      className={
                        consegna
                          ? "itemconsegna casa active"
                          : "itemconsegna casa"
                      }
                      onClick={() => setConsegna(true)}
                    >
                      {" "}
                      Consegna a casa{" "}
                    </div>
                    <div
                      className={
                        !consegna
                          ? "itemconsegna negozio active"
                          : "itemconsegna negozio"
                      }
                      onClick={() => handleRitiroNegozio()}
                    >
                      {" "}
                      Ritiro in negozio
                    </div>
                  </div>
                </div>

                {consegna ? (
                  <>
                    <div className="formfield flexible flexcenter">
                      <div className="consegna">
                        <div
                          className={
                            !consegnaEstero
                              ? "itemconsegna casa active"
                              : "itemconsegna casa"
                          }
                          onClick={() => handleConsegnaEstero(false)}
                        >
                          {" "}
                          Italia{" "}
                        </div>
                        <div
                          className={
                            consegnaEstero
                              ? "itemconsegna negozio active"
                              : "itemconsegna negozio"
                          }
                          onClick={() => handleConsegnaEstero(true)}
                        >
                          {" "}
                          Estero
                        </div>
                      </div>
                    </div>

                    <div className="title2"> Indirizzo di spedizione </div>

                    <div className="flexible">
                      <div className="formfield">
                        <br />
                        <input
                          className="forminput"
                          name="nomeSpedizione"
                          type="text"
                          value={nomeSpedizione}
                          onChange={(e) => handleUserInput(e)}
                          required
                        />
                        <br />
                        <label
                          className={
                            nomeSpedizioneValid
                              ? "form__label iscomplete"
                              : "form__label"
                          }
                        >
                          {" "}
                          Nome *{" "}
                        </label>
                      </div>
                      <div className="formfield">
                        <br></br>
                        <input
                          className="forminput"
                          name="cognomeSpedizione"
                          type="text"
                          value={cognomeSpedizione}
                          onChange={(e) => handleUserInput(e)}
                          required
                        ></input>
                        <br />
                        <label
                          className={
                            cognomeSpedizioneValid
                              ? "form__label iscomplete"
                              : "form__label"
                          }
                        >
                          {" "}
                          Cognome *{" "}
                        </label>
                      </div>
                    </div>
                    <div className="flexible">
                      <div className="formfield">
                        <br />
                        <input
                          className="forminput"
                          name="indirizzo"
                          type="text"
                          value={indirizzo}
                          onChange={(e) => handleUserInput(e)}
                          required
                        />
                        <br />
                        <label
                          className={
                            indirizzoValid
                              ? "form__label iscomplete"
                              : "form__label"
                          }
                        >
                          {" "}
                          Indirizzo *{" "}
                        </label>
                      </div>
                      <div className="formfield">
                        <br></br>
                        <input
                          className="forminput inputncivico"
                          name="ncivico"
                          type="text"
                          value={ncivico}
                          onChange={(e) => handleUserInput(e)}
                          required
                        ></input>
                        <br />
                        <label
                          className={
                            ncivicoValid
                              ? "form__label iscomplete"
                              : "form__label"
                          }
                        >
                          {" "}
                          N°civico *{" "}
                        </label>
                      </div>
                    </div>

                    <div className="flexible">
                      <div className="formfield città">
                        <br></br>
                        <input
                          className="forminput inputcittà"
                          name="città"
                          type="text"
                          value={città}
                          onChange={(e) => handleUserInput(e)}
                          required
                        ></input>
                        <br />
                        <label
                          className={
                            cittàValid ? "form__label iscomplete" : "form__label"
                          }
                        >
                          {" "}
                          Città *{" "}
                        </label>
                      </div>
                      {!consegnaEstero &&
                        <div className="formfield provincia">
                          <br></br>
                          <select
                            name="provincia"
                            onChange={updateValue}
                            value={provincia}
                          >
                            <option value="AG">Agrigento</option>
                            <option value="AL">Alessandria</option>
                            <option value="AN">Ancona</option>
                            <option value="AO">Aosta</option>
                            <option value="AR">Arezzo</option>
                            <option value="AP">Ascoli Piceno</option>
                            <option value="AT">Asti</option>
                            <option value="AV">Avellino</option>
                            <option value="BA">Bari</option>
                            <option value="BT">Barletta-Andria-Trani</option>
                            <option value="BL">Belluno</option>
                            <option value="BN">Benevento</option>
                            <option value="BG">Bergamo</option>
                            <option value="BI">Biella</option>
                            <option value="BO">Bologna</option>
                            <option value="BZ">Bolzano</option>
                            <option value="BS">Brescia</option>
                            <option value="BR">Brindisi</option>
                            <option value="CA">Cagliari</option>
                            <option value="CL">Caltanissetta</option>
                            <option value="CB">Campobasso</option>
                            <option value="CE">Caserta</option>
                            <option value="CT">Catania</option>
                            <option value="CZ">Catanzaro</option>
                            <option value="CH">Chieti</option>
                            <option value="CO">Como</option>
                            <option value="CS">Cosenza</option>
                            <option value="CR">Cremona</option>
                            <option value="KR">Crotone</option>
                            <option value="CN">Cuneo</option>
                            <option value="EN">Enna</option>
                            <option value="FM">Fermo</option>
                            <option value="FE">Ferrara</option>
                            <option value="FI">Firenze</option>
                            <option value="FG">Foggia</option>
                            <option value="FC">Forl&igrave;-Cesena</option>
                            <option value="FR">Frosinone</option>
                            <option value="GE">Genova</option>
                            <option value="GO">Gorizia</option>
                            <option value="GR">Grosseto</option>
                            <option value="IM">Imperia</option>
                            <option value="IS">Isernia</option>
                            <option value="AQ">L'aquila</option>
                            <option value="SP">La spezia</option>
                            <option value="LT">Latina</option>
                            <option value="LE">Lecce</option>
                            <option value="LC">Lecco</option>
                            <option value="LI">Livorno</option>
                            <option value="LO">Lodi</option>
                            <option value="LU">Lucca</option>
                            <option value="MC">Macerata</option>
                            <option value="MN">Mantova</option>
                            <option value="MS">Massa-Carrara</option>
                            <option value="MT">Matera</option>
                            <option value="ME">Messina</option>
                            <option value="MI">Milano</option>
                            <option value="MO">Modena</option>
                            <option value="MB">Monza e Brianza</option>
                            <option value="NA">Napoli</option>
                            <option value="NO">Novara</option>
                            <option value="NU">Nuoro</option>
                            <option value="OR">Oristano</option>
                            <option value="PD">Padova</option>
                            <option value="PA">Palermo</option>
                            <option value="PR">Parma</option>
                            <option value="PV">Pavia</option>
                            <option value="PG">Perugia</option>
                            <option value="PU">Pesaro e Urbino</option>
                            <option value="PE">Pescara</option>
                            <option value="PC">Piacenza</option>
                            <option value="PI">Pisa</option>
                            <option value="PT">Pistoia</option>
                            <option value="PN">Pordenone</option>
                            <option value="PZ">Potenza</option>
                            <option value="PO">Prato</option>
                            <option value="RG">Ragusa</option>
                            <option value="RA">Ravenna</option>
                            <option value="RC">Reggio Calabria</option>
                            <option value="RE">Reggio Emilia</option>
                            <option value="RI">Rieti</option>
                            <option value="RN">Rimini</option>
                            <option value="RM">Roma</option>
                            <option value="RO">Rovigo</option>
                            <option value="SA">Salerno</option>
                            <option value="SS">Sassari</option>
                            <option value="SV">Savona</option>
                            <option value="SI">Siena</option>
                            <option value="SR">Siracusa</option>
                            <option value="SO">Sondrio</option>
                            <option value="SU">Sud Sardegna</option>
                            <option value="TA">Taranto</option>
                            <option value="TE">Teramo</option>
                            <option value="TR">Terni</option>
                            <option value="TO" selected>Torino</option>
                            <option value="TP">Trapani</option>
                            <option value="TN">Trento</option>
                            <option value="TV">Treviso</option>
                            <option value="TS">Trieste</option>
                            <option value="UD">Udine</option>
                            <option value="VA">Varese</option>
                            <option value="VE">Venezia</option>
                            <option value="VB">Verbano-Cusio-Ossola</option>
                            <option value="VC">Vercelli</option>
                            <option value="VR">Verona</option>
                            <option value="VV">Vibo valentia</option>
                            <option value="VI">Vicenza</option>
                            <option value="VT">Viterbo</option>
                          </select>
                          <br />
                          <label className={
                            "form__label iscomplete"
                          }>
                            {" "}
                            Provincia *{" "}
                          </label>
                        </div>
                      }
                      {consegnaEstero &&
                        <div className="formfield città">
                          <br></br>
                          <input
                            className="forminput inputcittà"
                            name="stato"
                            type="text"
                            value={stato}
                            onChange={(e) => handleUserInput(e)}
                            required
                          ></input>
                          <br />
                          <label
                            className={
                              statoValid ? "form__label iscomplete" : "form__label"
                            }
                          >
                            {" "}
                            Stato *{" "}
                          </label>
                        </div>
                      }

                      <div className="formfield divCAP">
                        <br></br>
                        <input
                          className="forminput inputCAP"
                          name="CAP"
                          type="text"
                          value={CAP}
                          onChange={(e) => handleUserInput(e)}
                          required
                        />
                        <br />
                        <label
                          className={
                            CAPValid ? "form__label iscomplete" : "form__label"
                          }
                        >
                          {" "}
                          CAP *{" "}
                        </label>
                        <p> {CAPerror} </p>
                      </div>
                    </div>
                    <div className="mb-m">
                      <div className="title2" style={{ paddingBottom: "0px" }}>
                        {" "}
                        Scegli la tipologia di spedizione{" "}  </div>
                      <div className="ml-l mb-s"> Nota: tutti gli ordini saranno evasi entro 24/48H dalla loro ricezione <br></br> Per le isole i tempi di consegna si allungano di 12 ore </div>
                      <div>
                        <div className="ml-l">
                          <div className="inputradio">
                            <input
                              type="radio"
                              id="SDA"
                              name="spedizione"
                              value="SDA"
                              defaultChecked
                              onClick={() => handlespedizione("SDA")}
                            />
                            <div className="labelradio">
                              <span className={spedizione == "SDA" && "bold"}>
                                SDA
                              </span>{" "}
                              (9€)
                            </div>
                          </div>

                          <div className="inputradio">
                            <input
                              type="radio"
                              id="SDA"
                              name="spedizione"
                              value="UPS"
                              onClick={() => handlespedizione("UPS")}
                            />
                            <div className="labelradio">
                              <span className={spedizione == "UPS" && "bold"}>
                                UPS
                              </span>{" "}
                              (12€)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="ritiro">
                    <div className="title2"> Ritiro in </div>

                    <div className="formfield d-flex align-items-center">
                      <div style={{ marginRight: "2em" }}>Corso San Maurizio 34/F, 10124, Torino</div>
                      <div className="" onClick={() => openInNewTab('https://goo.gl/maps/EbEH1vdEn81amEsn9')}>
                        <Map></Map>
                      </div>
                    </div>
                    <div className="formfield">La tesi sarà pronta in 24h dalla ricezione dell'ordine</div>

                    <div className="boxpagine text-center" >
                      <div className="mb-s">Hai assolutamente bisogno di avere la tesi in giornata?
                        Inviaci ora il preventivo, ti ricontatteremo non appena possibile.</div>

                    {/* FORM PREVENTIVO */}

                      <div className="d-flex">
                        <form
                          className="w-100"
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                           
                          <div
                            className={
                              formValid && formEmergenzaValid ? "btninput w-100" : "button inviodisabled"
                            }
                            onClick={() => handlerichiesta()}
                          >
                            <div
                              className={formValid && formEmergenzaValid? "linkactive" : "linkdisabled"}
                            >
                              {textbutton}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}

              </form>
            </div>
          </div>
          <div className="divbottoni">
            <Link className="button flexible mw-m" to="/print/rilegature">
              <ChevronLeft size={12} />
              {" "}
              Indietro{" "}
            </Link>


            <Link
              onClick={() => sendinfo()}
              className={formValid ? "btninput mw-m linkactive" : "button disabled mw-m linkdisabled"}
              to="/print/pagamento"
            >
              {" "}
              Prosegui{" "}
              <ChevronRight size={12} color={formValid ? "white" : "grey"} />
            </Link>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dati;
