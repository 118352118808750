import React from 'react'
import { Link } from "react-router-dom";

function Fascia() {
  return (
    <div className="fascia hidemobile">
        <div className="imgcopertina">
        </div> 
        <div className="cont-text-fascia">
          <div className="text-36 bold mb-xs">La tua tesi in pochi click!</div>
          <div className="text-m text-fascia mb-s">Ti offriamo il miglior sistema per stampare la tua tesi, in pochi e semplici passaggi. <br></br>
          Carica il file, personalizza la tesi e procedi al pagamento. <br/>
          Per essere certi che il lavoro venga svolto nel migliore dei modi controlliamo il tuo file prima di stamparlo.</div>  
          <div className='cont_button'>
            <Link className="btnsecondary mt-l px-4 fascia-button" to="/print">
            {" "}
            Stampa subito la tua tesi{" "}
          </Link>
        </div>
        </div>
    </div>
  )
}

export default Fascia