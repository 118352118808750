import React from "react";
import { Document} from "react-pdf";
import { useState, useEffect } from "react";
import "./Css folder/Colore.css";
import Pagina from "./Pagina";
import { Container, Row} from "react-bootstrap";
import Dropdownmenu from "./Dropdownmenu";
import Immagine from "./Immagine";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import API from "../API";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import Footer from "./Footer";

function Colore(props) {
  const [numPages, setNumPages] = useState(null);
  const [allcolor, setAllColor] = useState(true);
  const [allbn, setAllBn] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [path, setPath] = useState("");
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {


    let path = window.localStorage.getItem("pathtesi");
    if (path) {
      setPath(path);
    }

    let pagesloaded = window.localStorage.getItem("loaded");
    if (pagesloaded) {
      setLoaded(pagesloaded);
    } else {
      window.localStorage.setItem("loaded", 0);
    }

    if (window.localStorage.getItem("finishloading")) {
      setNumPages(JSON.parse(window.localStorage.getItem("numpagine")));
      setLoading(false);
    }

    let paginebn = window.localStorage.getItem("paginebn");
    let paginecolore = window.localStorage.getItem("paginecolori");

    if(paginebn && paginecolore) {
    props.handletotale(paginebn,paginecolore);

    props.handlepaginecolore(paginecolore);
    props.handlepaginebn(paginebn);
    }

  }, [loading]);

  var pagesloaded = 0;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    props.handlenumpagine(numPages);
    props.handleallpaginebn();
  }


  function setallcolor() {
    setClicked(!clicked);
    setAllBn(false);
    setAllColor(true);
    props.handleallpaginecolore();
    let temparray = new Array();
    for (let i = 0; i < numPages; i++) {
      let pagecolor = { id: i, color: true };
      temparray.push(pagecolor);
    }
    window.localStorage.setItem("arraycolor", JSON.stringify(temparray));
    window.localStorage.setItem("paginebn", 0);
    window.localStorage.setItem("paginecolori", numPages);
    let a = !update;
    setUpdate(a);
  }

  function setallbn() {
    setClicked(!clicked);
    setAllBn(true);
    setAllColor(false);
    props.handleallpaginebn();
    let temparray = new Array();
    for (let i = 0; i < numPages; i++) {
      let pagecolor = { id: i, color: false };
      temparray.push(pagecolor);
    }
    window.localStorage.setItem("arraycolor", JSON.stringify(temparray));
    window.localStorage.setItem("paginecolori", 0);
    window.localStorage.setItem("paginebn", numPages);
    let a = !update;
    setUpdate(a);
  }

  function finishloading() {
    pagesloaded++;
    window.localStorage.setItem("loaded", +loaded + +pagesloaded);

    if (pagesloaded % 7 == 0) {
      setLoaded(JSON.parse(window.localStorage.getItem("loaded")));
    }

    if (loaded == numPages || loaded + pagesloaded >= numPages) {
      window.localStorage.removeItem("loaded");
      let pathtesi = window.localStorage.getItem("pathtesi");
      const promises = [];
      let arraycolor = [];

      for (let i = 0; i < numPages; ++i) {
        let page = document.getElementsByClassName("react-pdf__Page__canvas");
        let dataurl = page[i].toDataURL("image/jpeg", 0.5);
        let a = JSON.stringify({
          data: dataurl,
          pagenumber: i,
          pathtesi: pathtesi,
        });
        let pagecolor = { id: i, color: false };
        arraycolor.push(pagecolor);
        window.localStorage.setItem("arraycolor", JSON.stringify(arraycolor));
        promises.push(API.SaveFileFromUri(a));
      }

      Promise.all(promises)
        .then(
          window.localStorage.setItem("numpagine", numPages),
          window.localStorage.setItem("paginecolori", 0),
          window.localStorage.setItem("paginebn", numPages),
          window.localStorage.setItem("finishloading", true),
          props.handlearraycolor(arraycolor),
          setLoading(false)
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="containerstampa">
     <div style={{ height:"100%", width:"100%"}}>
      <div className="containerrow">
        <div className="el1">
          <Dropdownmenu
            selected="colore"
            clickable={props.clickable}
            loading={loading}
          />
        </div>
        <div className="divcontcolore">
          <div className="containerconfig">
            <div className="el2 pagine">
              <Container fluid>
              <Row>
                <div className="title">2. Selezione Colore</div>
              </Row>
                {!loading && (<div className="flexible column">
              <div className="boxpagine rilegature-item p-4">
                <div className="itemtitle">
                  In questa sezione potrai scegliere il colore delle pagine della tua tesi 
                </div>
                <div className="d-flex flex-row">
                  <div
                      className={"circlebn littlecircle position-relative mr-m"}
                  ></div>
                    <span>Clicca su questo pallino per averla in B/N{" "}</span>
                </div>
                <div className="d-flex flex-row">
                  <div
                    className={"circle littlecircle position-relative mr-m"}
                  ></div>
                    <span>Clicca su questo pallino per averla a colori{" "}</span>
                </div>
                </div>    
                <div className={loading ? "rowcolorinactive" : "rowcolori"}>
                  <div className="divcolori">
                    <div className="button"  onClick={() => setallcolor()}>
                      {" "}
                      Tutte a colori
                    </div>
                  </div>
                  <div className="divcolori">
                    <div className="button" onClick={() => setallbn()}>
                      {" "}
                      Tutte in B/N
                    </div>
                  </div>
                </div>
                <div className="boxpagine">
                 <p>Pagine in B/N: {props.paginebn}</p>
                 <span>Pagine a colori: {props.paginecolori}</span> 
                </div>
                
                </div>)
                }
                <Row className="rowpagine">
                  <div
                    className={
                      loading ? "circleprogressbar" : "circleprogressinactive"
                    }
                  >
                    <div className="textloading">
                      {loaded} / {numPages} Pagine caricate
                    </div>
                  </div>

                  {window.localStorage.getItem("finishloading") ? (
                    <div className="document">
                      {Array(numPages)
                        .fill(1)
                        .map((_, i) => (
                          <div key={i} className="imgcontainer">
                            <Immagine
                              key={i}
                              numPages={numPages}
                              update={update}
                              arraycolorpages={props.arraycolorpages}
                              finishloading={finishloading}
                              handleallpaginecolore={props.handleallpaginecolore}
                              handleallpaginebn={props.handleallpaginebn}
                              AllColor={allcolor}
                              AllBn={allbn}
                              clicked={clicked}
                              showdivcolor={true}
                              handlecolorepagine={props.handlecolorepagine}
                              i={i}
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <Document
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="documentinactive"
                      file={"../../" + path}
                    >
                      {Array(numPages)
                        .fill(1)
                        .map((_, i) => (
                          <Pagina
                            finishloading={finishloading}
                            handleallpaginecolore={props.handleallpaginecolore}
                            handleallpaginebn={props.handleallpaginebn}
                            AllColor={allcolor}
                            AllBn={allbn}
                            clicked={clicked}
                            showdivcolor={true}
                            key={i}
                            handlecolorepagine={props.handlecolorepagine}
                            i={i}
                          />
                        ))}
                    </Document>
                  )}
                </Row>
              </Container>
            </div>
          </div>
          {loading ? (
            <></>
          ) : (
            <div className="divbottoni">
                <Link className="button flexible mw-m linksecondactive" to="/print/">
                <ChevronLeft size={12}/>
                  {" "}
                  Indietro{" "}
                </Link>
          
                <Link
                  className={loading ? "button disabled mw-m linkdisabled" : "btninput flexible mw-m linkactive"}
                  to="/print/rilegature"
                >
                  {" "}
                  Prosegui{" "}
                  <ChevronRight size={12}/>
                </Link>
            </div>
          )}               
        </div>
       
        {/*<div className="el4 totale">
          <Totale
            copie={props.ordini}
            showcopie={false}
            showpages={true}
            updatecolor={update}
            tot={props.totale}
            grammatura={props.grammatura}
            handlecolorepagine={props.handlecolorepagine}
            numpagine={props.numpagine}
            paginebn={props.paginebn}
            paginecolori={props.paginecolori}
            prezzopaginebn={props.pricepaginebn}
            prezzopaginecolori={props.pricepaginecolori}
          />
          </div>*/} 
      </div>
      <Footer/>
     </div>
    </div>
  );
}
export default Colore;
