import React, { useEffect } from "react";
import "./Css folder/Preventivo.css";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import Totale from "./Totale";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import API from "../API";
function Preventivo() {
  const [selectedmaterial, setSelectedMaterial] = useState("");
  const [rilegatura, setRilegatura] = useState("Rigida");
  const [cartoncino,setCartoncino] = useState(false);
  const [grammatura, setGrammatura] = useState("80g");
  const [paginecolori, setPagineColori] = useState(0);
  const [paginebn, setPaginebn] = useState(0);
  const [pricegrammatura, setPriceGrammatura] = useState(0.05);
  const [pricerilegatura, setPriceRilegatura] = useState(0);
  const [pricemateriale, setPriceMateriale] = useState(0);
  const [pricepaginecolori, setPricePaginecolori] = useState(0);
  const [pricepaginebn, setPricePaginebn] = useState(0);
  const [copie, setCopie] = useState(0);
  const [spedizione, setSpedizione] = useState("Ritiro in negozio");
  const [materiali_rigida,setMaterialiRigida] = useState([]);
  const [pricespedizione, setPriceSpedizione] = useState(0);
  const [totale, setTotale] = useState(0);
  const [materialiePrezzi,setMaterialiePrezzi] = useState([]);
  var prezzopagineacolori = 0.35;
  var prezzocarta80g = 0.05;
  var prezzocarta120g = 0.08;
  var prezzocartariciclata = 0.06;
  var prezzocartoncino = 5;
  var prezzomorbida = 12;


  const testorigida = 
  " E’ la rilegatura classica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartone rigido rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testomorbida = "E’ la rilegatura elegante, ma più economica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartoncino rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testocartoncino = "E’ la rilegatura più economica utilizzata per le Tesi di Laurea. La copertina è la fotocopia del frontespizio su un cartoncino di colore Rosso, Granata, Blu o Verde. Non vi è alcuna incisione. puoi trovare degli esempi.";


  useEffect(() => {
    let temptot;

    if (isNaN(pricepaginebn)) {
      temptot =
        Number(pricespedizione) +
        Number(pricemateriale) +
        Number(0) +
        Number(pricepaginecolori);
    } else if (isNaN(pricepaginecolori)) {
      temptot =
      Number(pricespedizione) + Number(pricemateriale) + Number(pricepaginebn) + Number(0);
    } else if (isNaN(pricepaginecolori) && isNaN(pricepaginebn)) {
      temptot = Number(pricespedizione) + Number(pricemateriale) + Number(0) + Number(0);
    } else {

      !cartoncino ? temptot =
        Number(pricespedizione) +
        Number(pricemateriale) +
        Number(pricepaginebn) +
        Number(pricepaginecolori) : temptot =
        Number(pricespedizione) +
        Number(pricerilegatura) +
        Number(pricepaginebn) +
        Number(pricepaginecolori)
    }

    setTotale(temptot.toFixed(2));
  }, [selectedmaterial, rilegatura, paginecolori, paginebn, grammatura, pricespedizione]);

  const updateValue = ({ target }) => {

    if(rilegatura == "Morbida") {
      setPriceMateriale(Number(prezzomorbida));
      setSelectedMaterial(target.value);
    }
    else {
    setSelectedMaterial(target.value);

    let copertina = materialiePrezzi.find(function (element) {
      return element.materiale == target.value;
   })

  setPriceMateriale(Number(copertina.prezzo));
  };
}
  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const valuenumber = e.target.valueAsNumber;

    switch (name) {
      case "paginecolori":
        setPagineColori(value);
        let temp = parseFloat(valuenumber * prezzopagineacolori).toPrecision(3);
        setPricePaginecolori(Number(temp));
        break;

      case "paginebn":
        setPaginebn(value);
        let temp1 = parseFloat(valuenumber * pricegrammatura).toPrecision(3);
        setPricePaginebn(Number(temp1));
        break;
    }
  };

  const handlerilegatura = (rilegatura) => {
    switch (rilegatura) {
      case "Rigida":
        setRilegatura("Rigida");
        let copertina = materialiePrezzi.find(function (element) {
          return element.materiale == selectedmaterial;
       })
        setPriceMateriale(copertina.prezzo);
        setCartoncino(false);
        break;
      case "Morbida":
        setRilegatura("Morbida");
        setPriceRilegatura(prezzomorbida);
        setPriceMateriale(prezzomorbida);
        setCartoncino(false);
        break;
      case "Cartoncino":
        setRilegatura("Cartoncino");
        setPriceRilegatura(prezzocartoncino);
        setCartoncino(true);
        break;
    }
  };

  const update = (price) => {
    let temp1 = parseFloat(paginebn * price).toFixed(2);
    setPricePaginebn(temp1);
  };

  const handlegrammatura = (grammatura) => {
    switch (grammatura) {
      case "80g":
        setGrammatura("80g");
        setPriceGrammatura(prezzocarta80g);
        update(prezzocarta80g);
        break;
      case "120g":
        setGrammatura("120g");
        setPriceGrammatura(prezzocarta120g);
        update(prezzocarta120g);
        break;
      case "riciclata":
        setGrammatura("riciclata");
        setPriceGrammatura(prezzocartariciclata);
        update(prezzocartariciclata);
        break;
    }
  };

  const handlespedizione = (spedizione) => {
    switch (spedizione) {
      case "SDA":
        setSpedizione("SDA");
        setPriceSpedizione(9);
        break;
      case "UPS":
        setSpedizione("UPS");
        setPriceSpedizione(12);
        break;
      case "Ritiro in negozio":
        setSpedizione("Ritiro in negozio");
        setPriceSpedizione(0);
        break;   
    }
  };

  const updateValueRilegatura = ({ target }) => {
    handlerilegatura(target.value);
  }

 useEffect(() => {

  API.GetAllMaterials().then((allmaterials) => {

    API.GetAllScritte().then((allscritte) => {
    
      API.GetPrezzi().then((data) => {

        let array = data.split(/\r?\n/);
        let materiale_prezzi = array.map((el) => {
  
          let obj = {"materiale":"" ,"prezzo":""};
          let splitted = el.split(" ");
  
          obj.materiale = splitted[0];
          obj.prezzo = splitted[1];
  
          return obj;
        })
        setMaterialiePrezzi(materiale_prezzi);
        const materialiRigida = materiale_prezzi.filter((el) => el.materiale !== "Tela" && el.materiale !== "Opera" && el.materiale !== "Quarzo" && el.materiale !== "Algora");
        setMaterialiRigida(materialiRigida);
        setPriceMateriale(materiale_prezzi[0].prezzo);
        setSelectedMaterial(materiale_prezzi[0].materiale);
        let temptot;

        if (isNaN(pricepaginebn)) {
          temptot =
            pricespedizione +
            pricerilegatura +
            Number(materiale_prezzi[0].prezzo) +
            Number(0) +
            Number(pricepaginecolori);
        } else if (isNaN(pricepaginecolori)) {
          temptot =
          pricespedizione +pricerilegatura + Number(materiale_prezzi[0].prezzo) + Number(pricepaginebn) + Number(0);
        } else if (isNaN(pricepaginecolori) && isNaN(pricepaginebn)) {
          temptot = pricespedizione +pricerilegatura + Number(materiale_prezzi[0].prezzo) + Number(0) + Number(0);
        } else {
          temptot =
            Number(pricespedizione) +
            Number(pricerilegatura) +
            Number(materiale_prezzi[0].prezzo) +
            Number(pricepaginebn) +
            Number(pricepaginecolori);
        }
        setTotale(temptot.toPrecision(4));
    })
  
    })

})},[])


  return (
    <div style={{height:"100%", width:"100%"}}>
      <div style={{height:"fit-content", width:"100%"}}>
        <Container fluid>
          <div>
            <div className="title">
              <div className="customtitle"> Preventivo rapido </div>
            </div>
            <div className="blabla">
              <div className="containerconfigflex">
                <div className="Secondpagecontainer ">
                  <div>
                    {" "}
                    <div className="rilegature-item tiporilegatura">
                      <div className="itemtitle">
                        {" "}
                        Scegli la tipologia di rilegatura{" "}
                      </div>
                      <div className="flexible_end">
                      
                          <div className="w-100">
                          <select
                            className="formselect"
                            label="Default select example"
                            onChange={updateValueRilegatura}
                          >
                            <option value={"Rigida"}>Rigida (a partire da 18€)</option>
                            <option value={"Morbida"}>Morbida ({prezzomorbida}€)</option>
                            <option value={"Cartoncino"}>Cartoncino ({prezzocartoncino}€)</option>
                          </select>
                        </div>
                        
                      </div>
                      <div style={{ width: "80%",paddingTop: "10px", fontSize:"14px"}}>
                        {rilegatura == "Rigida" ? testorigida : rilegatura == "Morbida" ? testomorbida : testocartoncino}
                      </div>
                    </div>
                  </div>
                  {rilegatura === "Rigida" &&
                  <div>
                    {" "}
                    <div className="rilegature-item tipomateriale">
                      <div className="itemtitle"> Scegli il tipo di materiale </div>
                      <div className="flexible_end">
                        <div className="w-100">
                          <select
                            className="formselect"
                            label="Default select example"
                            onChange={updateValue}
                          >
                            {materialiePrezzi.map((element,i) => 
                        <option defaultValue value={element.materiale}>
                          {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo} €`}
                        </option>)}
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                  </div>}
                  {rilegatura === "Morbida" &&
                  <div>
                    {" "}
                    <div className="rilegature-item tipomateriale">
                      <div className="itemtitle"> Scegli il tipo di materiale </div>
                      <div className="flexible_end">
                        <div className="w-100">
                          <select
                            className="formselect"
                            label="Default select example"
                            onChange={updateValue}
                          >
                            {materiali_rigida.map((element,i) => 
                        <option defaultValue value={element.materiale}>
                          {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo} €`}
                        </option>)}
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                  </div>}
                  <div>
                    <div className="rilegature-item">
                      <div className="itemtitle">Pagine in bianco e nero</div>
                      <div className="flexible_end">
                          <input
                            className="customforminput"
                            name="paginebn"
                            min="0"
                            type="number"
                            value={paginebn}
                            required
                            onChange={(e) => handleUserInput(e)}
                          />
                          <div className="pricepag"> X {pricegrammatura} € </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="rilegature-item">
                      <div className="itemtitle">Pagine a colori</div>
                      <div className="flexible_end">
                          <input
                            className="customforminput"
                            name="paginecolori"
                            type="number"
                            min="0"
                            value={paginecolori}
                            required
                            onChange={(e) => handleUserInput(e)}
                          />
                          <div className="pricepag"> X 0.35€ </div>  
                      </div>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <div className="rilegature-item tipocarta">
                      <div className="itemtitle">
                        {" "}
                        Scegli la tipologia di carta interna{" "}
                      </div>
                      <div className="tipocarta column">
                        <div className="inputradio">
                          <input
                            type="radio"
                            id="80g"
                            name="grammatura"
                            value="80g"
                            defaultChecked
                            onClick={() => handlegrammatura("80g")}
                          />
                          <div className="labelradio">
                            <span className={grammatura == "80g" && "bold"}>
                              Standard 80g
                            </span>{" "}
                            <span> ({prezzocarta80g}€)</span>
                          </div>
                        </div>

                        <div className="inputradio">
                          <input
                            type="radio"
                            id="120g"
                            name="grammatura"
                            value="120g"
                            onClick={() => handlegrammatura("120g")}
                          />
                          <div className="labelradio">
                            <span className={grammatura == "120g" && "bold"}>
                              Standard 120g
                            </span>{" "}
                            <span> ({prezzocarta120g}€) </span>
                          </div>
                        </div>

                        <div className="inputradio">
                          <input
                            type="radio"
                            id="riciclata"
                            name="grammatura"
                            value="riciclata"
                            onClick={() => handlegrammatura("riciclata")}
                          />
                          <div className="labelradio">
                            <span className={grammatura == "riciclata" && "bold"}>
                              Riciclata 65g
                            </span>{" "}
                            ({prezzocartariciclata}€)
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div> 
                    <div className="rilegature-item noborderbottom">
                      <div className="itemtitle" style={{paddingBottom:"0px"}}>
                        {" "}
                        Scegli la tipologia di spedizione{" "}  </div>
                        <div style={{fontSize: "14px" , paddingBottom:"10px"}}> Nota: tutti gli ordini saranno evasi entro 24/48H dalla loro ricezione <br></br> Per le isole i tempi di consegna si allungano di 12 ore </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div className="tipocarta column">
                        <div className="inputradio">
                          <input
                            type="radio"
                            id="SDA"
                            name="spedizione"
                            value="SDA"
                            onClick={() => handlespedizione("SDA")}
                          />
                          <div className="labelradio">
                            <span className={spedizione == "SDA" && "bold"}>
                              SDA
                            </span>{" "}
                            (9€)
                          </div>
                        </div>

                        <div className="inputradio">
                          <input
                            type="radio"
                            id="SDA"
                            name="spedizione"
                            value="UPS"
                            onClick={() => handlespedizione("UPS")}
                          />
                          <div className="labelradio">
                            <span className={spedizione == "UPS" && "bold"}>
                              UPS
                            </span>{" "}
                            (12€)
                          </div>
                        </div>
                        <div className="inputradio">
                          <input
                            type="radio"
                            id="Ritiro in negozio"
                            name="spedizione"
                            value="Ritiro in negozio"
                            defaultChecked
                            onClick={() => handlespedizione("Ritiro in negozio")}
                          />
                          <div className="labelradio">
                            <span className={spedizione == "Ritiro in negozio" && "bold"}>
                              Ritiro in negozio
                            </span>{" "}
                            (Gratis)
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coltotale">
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    marginTop:"20px",
                    marginBottom:"1em"
                  }}
                >
                  <div style={{ width: "100%"}}>
                    <Totale
                      copie={copie}
                      prev={true}
                      cartoncino = {cartoncino}
                      prevpaginebn={paginebn}
                      prezzopaginebn={pricepaginebn}
                      prezzopaginecolori={pricepaginecolori}
                      prevpaginecolori={paginecolori}
                      rilegatura={rilegatura}
                      pricerilegatura={pricerilegatura}
                      materiale={selectedmaterial}
                      pricemateriale={pricemateriale}
                      spedizione={spedizione}
                      pricespedizione={pricespedizione}
                      tot={totale}
                    />
                  </div>
                  <div className="btninput w-100" style={{ textAlign: "center" }}>
                    <Link to="/print" className="linkactive">
                      {" "}
                      Continua la tua configurazione{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer></Footer>
  </div>
  );
}

export default Preventivo;
