import React, { useEffect } from "react";
import Dropdownmenu from "./Dropdownmenu";
import PayPal from "./PayPal";
import API from "../API";
import { Link, useNavigate} from "react-router-dom";
import { ChevronLeft } from "react-bootstrap-icons";
import "./Css folder/Pagamento.css";
import Ordine from "./Ordine";
import Footer from "./Footer";


function Pagamento(props) {

  const navigate = useNavigate();

  function CheckoutStripe(price) {

    let priceamount = price.toString().split(".").join("");
    priceamount = parseInt(priceamount);

    console.log(typeof(priceamount));

    API.StripePayment(priceamount)
    .then( data => console.log(data) )
    .catch((err) => console.log(err));
  }


  useEffect(() => {

    let tempPagineColori = new Array();
    let tempPagineBN = new Array();

    props.arraycolor.forEach((pagina) => {
      if(pagina.color)
        tempPagineColori.push(pagina.id);
      else
        tempPagineBN.push(pagina.id);  
    })

    /*if(props.ordini.length <= 0)
      {
        navigate("/print/rilegature");
      } 
    */
  
  },[])

  
  return (
    <div className="containerstampa">
      <div className="containerrow">
        <div className="el1">
          <Dropdownmenu selected={"pagamento"} clickable={props.clickable} />
        </div>
        <div className="divcontrilegature">
          <div className="containerconfig2">
          <div>
            <div className="title">5. Riepilogo e checkout</div>
          </div>
          <div className="el2 riepilogo">
            <div className="divriepilogo">

            <div className="flexible mb-s borderbottom pb">
                <div className="title-medium">1</div>
                <div className="ml-l">
                  <div className="title-medium mb-s"> Dati personali </div>
                  <div>
                    <div>{props.dati.Nome} {props.dati.Cognome}</div>
                    <div>{props.dati.Email}</div>
                    <div>{props.dati.Telefono}</div>
                  </div>
                </div>
            </div>    

              <div className="flexible mb-s borderbottom pb">
                <div className="title-medium">2</div>
                <div className= {props.consegna ? "ml-l" : "ml-l noborderbottom"}>
                  <div className="title-medium mb-s"> Indirizzo di Spedizione </div>
                  <div className="datispedizione">
                    {props.consegna ? (
                      <div>
                        <div>
                          {props.dati.NomeSpedizione} {props.dati.CognomeSpedizione}
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                          <div>{props.dati.Indirizzo} {props.dati.Ncivico}</div>
                          <div>{props.dati.Città} {props.dati.Estero ? "" : `(${props.dati.Provincia})`}  {props.dati.CAP}</div>
                          <div>{props.dati.Stato}</div>
                        </div>

                        {props.dati.Note && (
                          <>
                            <div className="title-medium"> Note </div>
                            <div> {props.dati.Note} </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div> Ritiro in Corso San Maurizio 34/F, 10124, Torino</div>
                    )}
                  </div>
                </div>
              </div>
              {props.consegna &&
              <div className="flexible mb-s borderbottom pb">
                <div className="title-medium">3</div>
                <div className= {props.consegna ? "ml-l" : "ml-l noborderbottom"}>
                  <div className="title-medium mb-s"> Tipo di spedizione </div>
                  <div className="mb-s"> {props.spedizione}</div>
                </div>
              </div>
              }
              <div className="d-flex">
                <div className="title-medium">{props.consegna ? "4" : "3"}</div>
                <div className="ml-l">
                  <div className="title-medium mb-s"> Riepilogo copie </div>
                <div className="table">
                  <tr>
                    <th style={{ width: "20%" }}> </th>
                    <th style={{ width: "45%" }}> </th>
                    <th className="hidemobile" style={{ width: "10%" }}> Quantità </th>
                    <th className="hidemobile" style={{ width: "5%" }}> Prezzo </th>
                  </tr>

                  {props.ordini.map((ordine, i) => (
                    <div className="ordine borderbottom">
                      <Ordine key={i} ordine={ordine} />
                    </div>
                  ))}
                </div>
                 </div>
              </div>
                 
            </div>
            <div className="divpagamento">
            <div className="tableordini last grey">
               <div className="bordergray">
                  <div className="title-large mb-s"> Riepilogo ordine</div>
                  <div className="flexible small-line-height">
                    <p>Articoli:</p>
                    <p className="left-auto">{props.totale}€</p>
                  </div>
                  <div className="flexible small-line-height">
                    <p>Costi di spedizione:</p>
                    <p className="left-auto">{props.prezzoSpedizione}€</p>
                  </div>
               </div>

               <div className="title-large primary flexible mt-l">
                <div>Totale ordine:</div>
                <div className="left-auto">{props.totaleConSpedizione}€</div>
              </div>
              </div>
              <div className="mt-xl">
                <div className="choices">
                  <div className="title-medium mb-s"> Metodo di pagamento: </div>
                </div>
                <div className="paypal_buttons">
                  <PayPal
                    totale={props.totaleConSpedizione}
                    ordini={props.ordini}
                    fronteretro={props.fronteretro}
                    consegna={props.consegna}
                    dati={props.dati}
                    corriere={props.spedizione}
                    arraycolor={props.arraycolor}
                    prezzoSpedizione ={ props.prezzoSpedizione}
                  />
                </div>
                <div className="btninput hidden" onClick={()=> CheckoutStripe(props.totaleConSpedizione)}>
                    Paga con carta di credito
                  </div>
              </div>
              
          </div>
        </div>
        
          </div>

        <div className="divbottoni">
              
                <Link className="button flexible mw-m" to="/print/dati">
                 <ChevronLeft size={12}/>
                  Indietro{" "}
                </Link>
              
          </div>   
          {/*<ConfirmationOrder
                    cliente = {false}
                    totale={props.totaleConSpedizione}
                    ordini={props.ordini}
                    fronteretro={props.fronteretro}
                    consegna={props.consegna}
                    arraycolor={props.arraycolor}
                    dati={props.dati}
                    prezzoSpedizione ={props.prezzoSpedizione}
                    corriere={props.spedizione}
                    colorpagesarray = {colorpagesarray}
                  />*/}
        </div>
        
      
      </div>
      <Footer/>
   </div> 
  );
}

export default Pagamento;
