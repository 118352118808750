import React from "react";
import { useNavigate, Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import "./Css folder/OrderComplete.css";

function OrderComplete() {

  return (
    <div className="d-flex flex-column flex-lg-row w-100">
      <div className="left-container">
        <div className="wrapper">
          {" "}
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <p style={{ color: "black" }}>Il tuo ordine è andato a buon fine!</p>
          <p style={{ color: "black" }}>
            A breve riceverai una email con i dettagli del tuo ordine.
          </p>
        <div className="d-flex">
          <div className="btninput">
          <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="25px"
      width="25px"
    >
      <path d="M12.71 2.29a1 1 0 00-1.42 0l-9 9a1 1 0 000 1.42A1 1 0 003 13h1v7a2 2 0 002 2h12a2 2 0 002-2v-7h1a1 1 0 001-1 1 1 0 00-.29-.71zM6 20v-9.59l6-6 6 6V20z" />
    </svg>
            <Link className="linkactive" to="/">
              {" "}
              Torna alla homepage{" "}
            </Link>
          </div>
          
          <ReactWhatsapp
              className="whatsappdiv"
              number="39011-815-9250"
              message="Ciao, vorrei sapere quando sarà pronta la mia tesi"
            >
              <div className="btnsecondary whatsappbutton">
              <div className="whatsappimg2"></div>
              Scopri quando sarà pronta al ritiro o sarà spedita la tua tesi! 
              </div>
            </ReactWhatsapp>
            </div>
        </div>
      </div>
      <div className="right-container">
        <iframe
            title="Sondaggio Ecoduemila"
            src="https://forms.gle/4FxfB5gWdbLeU7Y37"
            width="100%"
            height="100%"
          />
      </div>
    </div>
  );
}

export default OrderComplete;
