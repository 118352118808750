import React from "react";
import { Container } from "react-bootstrap";
import Servizio from "./Servizio";
import Footer from "./Footer";
import "./Css folder/Servizi.css";

function Servizi() {
  return ( 
  <div className="containerservizi">
    <Container fluid className="h-100 w-100">
      <div className="m-80" style={{height: "257px"}}>
        <img src="../../immagine-generale.jpg" className="w-100 h-100"></img>
      </div>
        
      <div className="m-80 mb-none"> 
        <div className="text-xl semibold">Altri servizi</div>
        <div className="text-s">
          
        </div>
      </div>
      
      <div className="m-80 mt-40 servizigrid">
        <div className="cont_servizio"><Servizio immagine="../../cartoncino.jpg" titolo="Cartoncino" descrizione="La rilegatura a caldo economica per le Tesi di Laurea." /></div>
        <div className="cont_servizio"><Servizio immagine="../../spirale-plastica.jpg" titolo="Rilegatura a spirale" descrizione="La rilegatura per ogni volume che ha bisogno di essere sfogliato più e più volte."/></div>
        <div className="cont_servizio"><Servizio immagine="../../brossure.jpg" titolo="Brossura" descrizione="La brossura grecata e fresata è la più comune tra le rilegature. Le pagine, incollate alla copertina, permettono una soluzione facile ed economica per le tue pubblicazioni."/></div>
        <div className="cont_servizio"><Servizio immagine="../../punto-metallico.jpg" titolo="Punto metallico" descrizione ="Il classico sistema di rilegatura con i due punti metallici è ideale per brochure, riviste, opuscoli e listini."/></div>
        <div className="cont_servizio"><Servizio immagine="../../grande-formato.jpg" titolo="Stampa grande formato" descrizione="A3, A2, A1, A0, 50x70, 70x100 e formato personalizzato." /></div>
        <div className="cont_servizio quadri"><Servizio immagine="../../fotoquadro.jpg" titolo="Fotoquadri" descrizione="Disponibilità telai da 30, 40, 50, 70 e 100cm."/></div>
        <div className="cont_servizio"><Servizio immagine="../../flyer.jpg" titolo="Flyer" descrizione ="A5, A6, 10x15, 13x15, 15x18 e formato personalizzato."/></div>
        <div className="cont_servizio"><Servizio immagine="../../bv.jpg" titolo="Biglietti da visita" descrizione =" 85x55 e 55x55 su 300/350/400g normali o plastificati."/></div>
      </div>

      <div className="m-0">
        <Footer/>
      </div>
    </Container>
  </div> 
  );
}

export default Servizi;
