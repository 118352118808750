import React from 'react'
import { Container } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import "./Css folder/FAQ.css"
import Footer from './Footer';

function FAQ() {
  return (
    <div style={{ height:"100%", width:"100%"}}>
    <Container className="w-100 d-flex flex-column m-5 p-5 containerfaq">
        <h1> F.A.Q. - Domande frequenti </h1>
       <Accordion className="w-100" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
            <Accordion.Header>Come posso inviare il file della mia tesi? </Accordion.Header>
            <Accordion.Body>
            Puoi inviare la tua tesi online seguendo la procedura che trovi
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header>In che formato devo inviare la Tesi?</Accordion.Header>
            <Accordion.Body>
            Il file dovrà rigorosamente essere in formato Pdf, non stampiamo file in Word/ Open Office di nessun tipo o versione. L’apertura di un file in Word/Open Office (su un computer che non sia quello da cui è stato creato) cambia totalmente la formattazione e quindi risulta instampabile
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>In quanto tempo è pronta la Tesi?</Accordion.Header>
            <Accordion.Body>
            La tesi è pronta in 24h effettive (es. l’ordine viene confermato il Lunedì alle 11, la tesi sarà pronta al ritiro Martedì dalle 11 in poi)
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>Dove trovo i materiali disponibili?</Accordion.Header>
            <Accordion.Body>
            Puoi visualizzare il catalogo completo 
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>E’ possibile avere un preventivo?</Accordion.Header>
            <Accordion.Body>
            Puoi richiedere il tuo preventivo
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Accordion.Header>Quali sono i tempi di spedizione in Italia, Isole ed Europa?</Accordion.Header>
            <Accordion.Body>
            Italia: 24/48h con Poste Italiane o Ups
Isole: 48/72h con Poste Italiane o Ups
Europa: oltre le 72h con Ups o altro corriere
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
            <Accordion.Header>E’ possibile assicurare la spedizione? </Accordion.Header>
            <Accordion.Body>
            con Poste Italiane o Ups puoi assicurare la tua spedizione selezionando l’opzione al momento del pagamento.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
            <Accordion.Header>Come posso tracciare il mio pacco?</Accordion.Header>
            <Accordion.Body>
            Copia il codice di tracciamento e recati sul sito del corriere scelto. Per le spedizioni estere che non usufruiscono di Poste Italiane o Ups verrà inviato via mail il tracking ed il sito ufficiale dove monitorare la spedizione.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
            <Accordion.Header>Cosa succede alla mio pacco se il corriere non mi trova? </Accordion.Header>
            <Accordion.Body>
            Proverà a contattarti telefonicamente. Se non sarà possibile consegnare il pacco nel giorno stabilito, il corriere tenterà nuovamente nei giorni successivi. Solitamente dopo il secondo/terzo tentativo fallito verrà inviato al deposito dove sarà possibile ritirarlo entro una data decisa dall’azienda logistica.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
            <Accordion.Header>E’ possibile ritirare la tesi in negozio?</Accordion.Header>
            <Accordion.Body>
            Certo, durante il pagamento sarà possibile selezionare quale tipologia di consegna si preferisce.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
            <Accordion.Header>Quali modalità di pagamento posso utilizzare? </Accordion.Header>
            <Accordion.Body>
            Puoi pagare con Pay Pal, Satispay, Carta di Credito, Bonifico e Scalapay.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
            <Accordion.Header>E’ possibile pagare la mia tesi a rate?</Accordion.Header>
            <Accordion.Body>
            Se l’importo lo consente, al momento del pagamento Pay Pal e Scalapay forniranno l’opzione “Pagamento a rate” in automatico.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12">

            <Accordion.Header>E’ possibile fare una tesi fuori formato? </Accordion.Header>
            <Accordion.Body>
            Per avere una tesi fuori formato consigliamo di chiamarci in negozio ( 0118159250 ) per avere un preventivo dettagliato.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">

            <Accordion.Header>Che cos’è la rilegatura Rigida?</Accordion.Header>
            <Accordion.Body>
            E’ la rilegatura classica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartone rigido rivestito dal materiale scelto. 
            L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">

            <Accordion.Header>Che cos’è la rilegatura Morbida? </Accordion.Header>
            <Accordion.Body>
            E’ la rilegatura elegante, ma più economica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartoncino rivestito dal 
            materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">

            <Accordion.Header>Che cos’è la rilegatura Cartoncino?</Accordion.Header>
            <Accordion.Body>
            E’la rilegatura più economica utilizzata per le Tesi di Laurea. La copertina è la fotocopia del frontespizio su un cartoncino di colore 
            Rosso, Granata, Blu o Verde. Non vi è alcuna incisione. puoi trovare degli esempi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="16">

            <Accordion.Header>Che cos’è la rilegatura in Brossura? </Accordion.Header>
            <Accordion.Body>
            Una rilegatura adatta alla realizzazione di volumi, cataloghi e libri, prodotti quindi di alta qualità. Vengono unite le pagine mediante 
            una colla molto resistente. puoi trovare degli esempi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="17">

            <Accordion.Header>Posso rilegare un volume con più di 350 fogli?</Accordion.Header>
            <Accordion.Body>
            Possiamo rilegare 350 fogli per un totale di 700 facciate in fronte e retro. Consigliamo di contattarci telefonicamente ( 0118159250 ) 
            se la stampa supera le 350 pagine solo fronte.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="18">

            <Accordion.Header>Come devono essere i margini/numeri per la stampa solo fronte?     </Accordion.Header>
            <Accordion.Body>
            I margini devono essere di almeno 25mm nel lato sinistro della rilegatura, a destra si consiglia almeno 15mm. I numeri di pagina 
            devono essere posizionati sul lato destro o centrali. I consigli sopra riportati fanno riferimento ad una tesi di circa 250 pagine, oltre 
            quel quantitativo si consiglia di contattarci telefonicamente ( 0118159250 ).
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="19">

            <Accordion.Header>Come devono essere i margini/numeri per la stampa fronte e retro?</Accordion.Header>
            <Accordion.Body>
            I margini devono essere di almeno 25mm nel lato sinistro della rilegatura, a destra si consiglia almeno 15mm. Essendo una stampa 
            fronte retro i margini ed i numeri dovranno essere speculari. I consigli sopra riportati fanno riferimento ad una tesi di circa 250 
            pagine, oltre quel quantitativo si consiglia di contattarci telefonicamente ( 0118159250 ).
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="20">

            <Accordion.Header>Che tipo di carta posso scegliere per la stampa? </Accordion.Header>
            <Accordion.Body>
            Carta Bianca 80/100/120, Carta Riciclata 70g, Cartoncino 200/300g
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="21">

            <Accordion.Header>Posso stampare solo alcune pagine a colori?</Accordion.Header>
            <Accordion.Body>
            Certamente, potrai scegliere quali pagine vuoi a colori durante la creazione dell’ordine della tua tesi .
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="22">

            <Accordion.Header>Posso avere la copertina diversa dal frontespizio? </Accordion.Header>
            <Accordion.Body>
            Basterà allegare il file della copertina rinominandola “Copertina” .
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="23">

            <Accordion.Header>Posso inserire degli allegati più grandi di un A4?</Accordion.Header>
            <Accordion.Body>
            La dimensione massima in altezza consigliata è di 297mm, la dimensione massima consigliata in larghezza è di 841mm. Per altre 
            misure si consiglia di contattarci telefonicamente.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="24">

            <Accordion.Header>Ho trovato un errore nella tesi, come posso rimediare? </Accordion.Header>
            <Accordion.Body>
            Puoi sostituire quante pagine vuoi (avranno ovviamente un costo a parte) prima che sia rilegata la tesi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="25">

            <Accordion.Header>Quante pagine posso sostituire in una tesi già rilegata?</Accordion.Header>
            <Accordion.Body>
            Puoi sostituire fino a 3 pagine al costo di €2,00 cad. Sconsigliamo di superare questo quantitativo poiché risulterebbe brutto visivamente
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="26">

            <Accordion.Header>E’ possibile sostituire l’intero blocco della tesi senza rifare la copertina? </Accordion.Header>
            <Accordion.Body>
            Certo, sia prima che dopo la rilegatura della tesi.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="27">

            <Accordion.Header>Il sito non funziona, come posso ordinare la mia tesi?</Accordion.Header>
            <Accordion.Body>
            Puoi contattarci via email ( legatoriaecoduemila@gmail.com ) o telefonicamente ( 0118159250 )
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="28">

            <Accordion.Header>Ci sono sovrapprezzi ordinandola direttamente al telefono o via mail? </Accordion.Header>
            <Accordion.Body>
            Assolutamente no, consigliamo però di acquistare direttamente sul sito.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="29">
            
            <Accordion.Header>I tempi di consegna si allungano?</Accordion.Header>
            <Accordion.Body>
            I tempi di consegna sono uguali, a patto che il lavoro da voi richiesto lo possa permettere.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="30">

            <Accordion.Header>Come posso pagare esternamente dal sito? </Accordion.Header>
            <Accordion.Body>
            Puoi pagare con Pay Pal, Satispay, Carta di Credito, Bonifico.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="31">

            <Accordion.Header>Ho altre domande, ma non sono presenti nelle FAQ, posso contattarvi?</Accordion.Header>
            <Accordion.Body>
            Certamente!
            </Accordion.Body>
        </Accordion.Item>
       </Accordion>
    </Container>
    <Footer/>
    </div>
  )
}

export default FAQ