import React from 'react'
import { Link } from 'react-router-dom';
import "./Css folder/Servizio.css";

function Servizio(props) {
  return (
    <Link to={`/servizi/${props.titolo}`} 
        state = {{description: props.descrizione, immagine:props.immagine}} 
        style={{background: "white" }}
        className="cont_imgservizio"
        onClick={() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}>
        <img className="imgservizio" src={props.immagine} alt={props.descrizione}></img>
        <div className="semibold scrittaservizio">{props.titolo}</div>
    </Link>
  )
}

export default Servizio