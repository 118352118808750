import React from "react";
import "./Css folder/Ordine.css";

function Ordine(props) {
  return (
    <div className="flexible">
      {" "}
      <div
      className="ordineclass riepilogo"
      >
        <img
          id="imgcopertina"
          className="imganteprima"
          src={`../../miniature/${
            props.ordine.type.materiale != "Cartoncino"
              ? props.ordine.type.materiale
              : "Ecopelle"
          }/${props.ordine.type.copertina_src}`}
        />

        <img
          id="imgscritta"
          className="imgscritta"
          src={"../../scritteCopertina/" + props.ordine.type.scritta_src}
        />
      </div>
      <div
        className="colonna_ordine"
      >
        <div className="typeorder">
          <div className="title-medium"> Rilegatura</div>
          {props.ordine.type.rilegatura}
        </div>
        <div className="typeorder">
          <div className="title-medium"> Materiale</div>{" "}
          {props.ordine.type.materiale}{" "}
        </div>
        <div className="typeorder">
          <div className="title-medium"> Scritta</div>{" "}
          {props.ordine.type.scritta}{" "}
        </div>
        <div className="typeorder">
          <div className="title-medium"> Copertina</div>{" "}
          {props.ordine.type.copertina}{" "}
        </div>
        <div className="typeorder">
          <div className="title-medium"> Grammatura</div>{" "}
          {props.ordine.type.grammatura}{" "}
        </div>
        <div className="typeorder">
          <div className="title-medium"> Impaginazione </div>{" "}
          {props.ordine.type.impaginazione}{" "}
        </div>
      </div>{" "}
      <div className="hidemobile" style={{ marginLeft: "auto", width: "10%" }}>
        {props.ordine.value}
      </div>
      <div  className="hidemobile"> {props.ordine.prezzo}€</div>

      <div className="showmobile w-100 justify-content-between">
        <b>Quantità: </b>{props.ordine.value}
        <br/>
        <b>Prezzo: </b>{props.ordine.prezzo}
      </div>
    </div>
  );
}

export default Ordine;
