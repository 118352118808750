import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Servizi from "./components/Servizi";
import Preventivo from "./components/Preventivo";
import Help from "./components/Help";
import Stampa from "./components/Stampa";
import Home from "./components/Home/Home";
import Dropdownmenu from "./components/Dropdownmenu";
import Rilegature from "./components/Rilegature";
import Colore from "./components/Colore";
import Dati from "./components/Dati";
import Pagamento from "./components/Pagamento";
import OrderFailed from "./components/OrderFailed";
import OrderComplete from "./components/OrderComplete";
import Materiali from "./components/Materiali";
import ServizioRef from "./components/ServizioRef";
import FAQ from "./components/FAQ";
import Banner from "./components/Banner";
import ConfirmationOrder from "./components/ConfirmationOrder";

function App() {

  //props Navbar
  const [showdrop,setShowdrop] = useState(false);
  const [showdropflag,setShowdropflag] = useState("chiuso");

  //props Upload
  const [fronteRetro,setFronteRetro] = useState(false);


  //Props Colore
  const [showpages, setShowPages] = useState(false);
  const [loadingfinished, setLoadingFinished] = useState(false);
  const [pathcolore, setPathcolore] = useState("");
  const [pricegrammatura, setPriceGrammatura] = useState(0.05);
  const [pricepaginecolori, setPricePaginecolori] = useState(0);
  const [pricepaginebn, setPricePaginebn] = useState(0);
  const [grammatura, setGrammatura] = useState("80g");
  const [pathfrontespiziocolore, setPathFrontespizioColore] = useState("");
  const [arraycolorpages, setArrayColorPages] = useState([]);
  const [materialiarray, setMaterialiArray] = useState([]);
  const [materialiePrezzi, setMaterialiePrezzi] = useState([]);
  const [go, setGo] = useState(false);
  const [arraypagineacolori,setArrayPagineacolori] = useState([]);
  const [arraypagineinbn,setArrayPagineinbn] = useState([]);

  //Props Totale
  const [totale, setTotale] = useState(0.0);
  const [paginebn, setPaginebn] = useState(0);
  const [paginecolori, setPaginecolori] = useState(0);
  const [numpagine, setNumpagine] = useState(0);
  const [spedizione,setSpedizione] = useState("SDA");
  const [prezzoSpedizione,setPrezzoSpedizione] = useState(9);
  const [totaleConSpedizione,setTotaleConSpedizione] = useState(0);
  //Props Dropdownmenu
  const clickable = ["document"];
  const clickable2 = ["document", "colore"];
  const clickable3 = ["document", "colore", "rilegature"];
  const clickable4 = ["document", "colore", "rilegature", "dati"];
  const clickable5 = ["document", "colore", "rilegature", "dati", "pagamento"];

  //Props Dati
  const [dati, setDati] = useState("");
  const [consegna, setConsegna] = useState(false);
  //Protect routes
  const [arrayvisitable, setArrayVisitable] = useState(["document"]);

  //Props General
  const [copie, setCopie] = useState([]);
  const [update, setUpdate] = useState(false);
  const [buttonvisitable, setButtonVisitable] = useState(false);

  var prezzopagineacolori = 0.35;
  var prezzocarta80g = 0.05;
  var prezzocarta120g = 0.08;
  var prezzocartariciclata = 0.06;
  var prezzocartoncino = 5;
  var prezzomorbida = 12;

  const updatePricePaginebn = (price) => {
    let temp1 = parseFloat(paginebn * price).toFixed(2);
    setPricePaginebn(temp1);
   

    let tot = parseFloat(Number(pricepaginecolori) + Number(temp1)).toFixed(2);
    setTotale(tot);
  };

  const handlegrammatura = (grammatura) => {
    switch (grammatura) {
      case "80g":
        setGrammatura("80g");
        setPriceGrammatura(prezzocarta80g);
        updatePricePaginebn(prezzocarta80g);
        break;
      case "120g":
        setGrammatura("120g");
        setPriceGrammatura(prezzocarta120g);
        updatePricePaginebn(prezzocarta120g);
        break;
      case "riciclata":
        setGrammatura("riciclata");
        setPriceGrammatura(prezzocartariciclata);
        updatePricePaginebn(prezzocartariciclata);
        break;
    }
  };



 useEffect (() => {
  
    setTotaleConSpedizione(parseFloat(Number(totale) + Number(prezzoSpedizione)).toFixed(2));
 },[totale,prezzoSpedizione])


  const updateSpedizione = (tipoSpedizione, prezzoSpedizione) => {

    setSpedizione(tipoSpedizione);
    setPrezzoSpedizione(prezzoSpedizione);
  }


  const handlecopie = (newcopia) => {
    let prezzocopia = 0;
    let prezzogrammatura = 0;
    switch (newcopia.grammatura) {

      case "80g":
      prezzogrammatura = prezzocarta80g;
      break;

      case "120g":
      prezzogrammatura = prezzocarta120g;
      break;

      case "riciclata":
      prezzogrammatura = prezzocartariciclata;
      break;

    }
  
    let prezzopagineinterne = parseFloat(Number(paginecolori * prezzopagineacolori).toFixed(2)) + parseFloat(Number(paginebn * prezzogrammatura).toFixed(2)); 

    if(newcopia.rilegatura == "Morbida") {
      prezzocopia = parseFloat(Number(prezzomorbida) + Number(prezzopagineinterne)).toFixed(2); 
    }

    else if(newcopia.rilegatura == "Cartoncino"){
    prezzocopia = parseFloat(Number(prezzocartoncino) + Number(prezzopagineinterne)).toFixed(2);
    newcopia.materiale = "Cartoncino";
    }
    else {
    let materiale = materialiePrezzi.find((element) => {
      return element.materiale == newcopia.materiale;
    })
    prezzocopia = parseFloat(Number(materiale.prezzo) + Number(prezzopagineinterne)).toFixed(2);
  }


    let newordine = { type: newcopia, value: 1, prezzo: prezzocopia };
    let a = 0;
    var indice;
    copie.forEach((element, i) => {
      if (JSON.stringify(element.type) === JSON.stringify(newordine.type)) {
        indice = i;
        a = 1;
      }
    });

    if (a === 1) {
      let temparray = copie.slice();
      temparray[indice].value = temparray[indice].value + 1;
      temparray[indice].prezzo = parseFloat(parseFloat(temparray[indice].prezzo) + parseFloat(prezzocopia)).toFixed(2);
      setCopie(temparray);
      setButtonVisitable(true);
      let upd = !update;
      setUpdate(upd);
    } else {
      let temp = [...copie, newordine];
      setCopie(temp);
      let upd = !update;
      setUpdate(upd);
      setButtonVisitable(true);
    }
  };

  const menocopia = (index) => {
    let temparray = [...copie];
    let copia = copie.at(index);
    var temp = Object.assign({}, copia);
    temp.value = copia.value - 1;
    let tmp = copia.prezzo;
    tmp = tmp / copia.value;
    temp.prezzo = parseFloat(temp.prezzo - tmp).toFixed(2);
    if (temp.value === 0) {
      removecopia(index);
      let upd = !update;
      setUpdate(upd);
    } else {
      temparray[index] = temp;
      setCopie(temparray);
      let upd = !update;
      setUpdate(upd);
    }
  };

  const piucopia = (index) => {
    let temparray = [...copie];
    let copia = copie.at(index);
    var temp = Object.assign({}, copia);
    temp.value = copia.value + 1;
    let tmp = copia.prezzo;
    tmp = tmp / copia.value;
    temp.prezzo = parseFloat(parseFloat(temp.prezzo) + parseFloat(tmp)).toFixed(2);
    temparray[index] = temp;
    setCopie(temparray);
    let upd = !update;
    setUpdate(upd);
  };

  const removecopia = (index) => {
    copie.splice(index, 1);
    let temp = [...copie];
    setCopie(temp);
    let upd = !update;
    setUpdate(upd);
    if (copie.length <= 0) setButtonVisitable(false);
  };

  const resetCopie = () => {
    setCopie([]);
    setTotale(0);
    setButtonVisitable(false);
  }

  const handleloading = (val) => {
    //setLoading(val);
  };

  const handlefinishloading = (val) => {
    setLoadingFinished(val);
  };

  const handlematerialieprezzi = (materialiePrezziarray) => {
    setMaterialiePrezzi(materialiePrezziarray)
  }
  const handletotale = (temp1,temp2) => {

    let temppricebn = parseFloat(temp1 * pricegrammatura).toFixed(2);
    setPricePaginebn(Number(temppricebn));
    let temppricecolori = parseFloat(temp2 * prezzopagineacolori).toFixed(2);
    setPricePaginecolori(Number(temppricecolori));
    let tot = parseFloat(Number(temppricebn) + Number(temppricecolori)).toFixed(2);
    setTotale(tot);
  };

  const handletotaleconcopie = (tot) => {
    setTotale(tot);
  }

  const handlepath = (p) => {
    setPathcolore(p);
  };

  const handlepathfrontespizio = (p) => {
    setPathFrontespizioColore(p);
  };

  const handlenumpagine = (pagine) => {
    setNumpagine(pagine);
    setShowPages(true);
  };

  const handlearraycolor = (arraycolor) => {
    setArrayColorPages(arraycolor);
  };

  const handlecolorepagine = (colore, i) => {
    if (colore) {
      let pagcolori = window.localStorage.getItem("paginecolori");
      pagcolori = parseInt(pagcolori) + 1;
      let pagbn = window.localStorage.getItem("paginebn");
      pagbn = parseInt(pagbn) - 1;
      setPaginebn(pagbn);
      setPaginecolori(pagcolori);
      let temp = parseFloat(pagcolori * prezzopagineacolori).toFixed(2);
      setPricePaginecolori(Number(temp));
      let temp1 = parseFloat(pagbn * pricegrammatura).toFixed(2);
      setPricePaginebn(Number(temp1));
      let tot = parseFloat(Number(temp) + Number(temp1)).toFixed(2);
      setTotale(tot);
      

      let objIndex = arraycolorpages.findIndex((obj) => obj.id === i);
      arraycolorpages[objIndex].color = true;
      window.localStorage.setItem(
        "arraycolor",
        JSON.stringify(arraycolorpages)
      );
      window.localStorage.setItem("paginecolori", pagcolori);
      window.localStorage.setItem("paginebn", pagbn);
    } else {
      let pagbn = window.localStorage.getItem("paginebn");
      pagbn = parseInt(pagbn) + 1;
      let pagcolori = window.localStorage.getItem("paginecolori");
      pagcolori = parseInt(pagcolori) - 1;
      setPaginecolori(pagcolori);
      setPaginebn(pagbn);
      let temp = parseFloat(pagcolori * prezzopagineacolori).toFixed(2);
      setPricePaginecolori(Number(temp));
      let temp1 = parseFloat(pagbn * pricegrammatura).toFixed(2);
      setPricePaginebn(Number(temp1));
      let tot = parseFloat(Number(temp) + Number(temp1)).toFixed(2);
      setTotale(tot);

      let objIndex = arraycolorpages.findIndex((obj) => obj.id === i);
      arraycolorpages[objIndex].color = false;
      window.localStorage.setItem(
        "arraycolor",
        JSON.stringify(arraycolorpages)
      );
      window.localStorage.setItem("paginebn", pagbn);
      window.localStorage.setItem("paginecolori", pagcolori);
      
    }
  };

  const handleallpaginebn = () => {
    setPaginecolori(0);
    setPaginebn(numpagine);
    if (arraycolorpages.length > 0) {
      arraycolorpages.forEach((obj) => {
        obj.color = false;
      });
    }
    let temp = parseFloat(0 * prezzopagineacolori).toFixed(2);
      setPricePaginecolori(Number(temp));
      let temp1 = parseFloat(numpagine * pricegrammatura).toFixed(2);
      setPricePaginebn(Number(temp1));

      let tot = parseFloat(Number(temp1)).toFixed(2);
      setTotale(tot);
  };

  const handleallpaginecolore = () => {
    setPaginebn(0);
    setPaginecolori(numpagine);
    if (arraycolorpages.length > 0) {
      arraycolorpages.forEach((obj) => {
        obj.color = true;
      });
    }
    let temp = parseFloat(numpagine * prezzopagineacolori).toFixed(2);
      setPricePaginecolori(Number(temp));
      let temp1 = parseFloat(0 * pricegrammatura).toFixed(2);
      setPricePaginebn(Number(temp1));

      
      let tot = parseFloat(Number(temp)).toFixed(2);
      setTotale(tot);
  };

  const handlepaginebn = (paginebn) => {
    setPaginebn(paginebn);
  }

  const handlepaginecolore = (paginecolore) => {
    setPaginecolori(paginecolore);
  }

  const handleFronteRetro = (value) => {

    setFronteRetro(value);
  }

  const addvisitableroute = (route) => {
    if (!arrayvisitable.includes(route)) {
      const temp = [...arrayvisitable, route];
      setArrayVisitable(temp);
    }
  };

  const sendinfo = (dati, consegna) => {
    if (consegna) setConsegna(true);
    else setConsegna(false);

    setDati(dati);
  };


  const handleshowdrop = (value) => {
    setShowdropflag(value);
  }


  {/*useEffect(() => {

    window.addEventListener('click', function(e){   
      if (document.getElementById('tesi_section').contains(e.target) ){
        setShowdrop("chiuso");
      } else if(!(document.getElementById('tesi_section').contains(e.target)) && !(document.getElementById('btnsel').contains(e.target))){
        if(showdropflag){
        setShowdrop("da chiudere");
      }
      }
    });

  },[showdrop]) */}


  useEffect(() => {

 


    let numpag = window.localStorage.getItem("numpagine");
    setNumpagine(numpag);

    let arraycolor = JSON.parse(window.localStorage.getItem("arraycolor"));
    if (arraycolor) {
      setArrayColorPages(arraycolor);
    }

    let _paginebn = window.localStorage.getItem("paginebn");
    setPaginebn(paginebn);
    let _paginecolore = window.localStorage.getItem("paginecolori");
    setPaginecolori(paginecolori);

    if(_paginebn && _paginecolore) {
     handletotale(_paginebn,_paginecolore);
    }

  }, []);

  useEffect(() => {
    if (copie.length > 0) {
      let tot = 0;

      copie.forEach((copia) => {
        tot = parseFloat((parseFloat(tot) + parseFloat(copia.prezzo))).toFixed(2);
      });
      //tot = parseFloat(parseFloat(tot) + parseFloat(pricepaginebn) + parseFloat(pricepaginecolori)).toFixed(2);
      setTotale(tot);
      setButtonVisitable(true);
    }
    
    else {
      let tot = 0;
      //tot = parseFloat( parseFloat(pricepaginebn) + parseFloat(pricepaginecolori)).toFixed(2);
      setTotale(tot);
      setButtonVisitable(false);
    }
  }, [update]);


  return (
    <div className="App">
      <Router>
        <Banner/>
        <Navbar/>
        <div className="Appcontent">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/orderfailed" element={<OrderFailed />} />
            <Route exact path="/ordersuccess" element={<OrderComplete />} />
            <Route exact path="/preventivo" element={<Preventivo />} />
            <Route exact path="/materiali" element={<Materiali />} />
            <Route exact path="/servizi" element={<Servizi />} />
            <Route exact path="/servizi/:id_servizio" element={<ServizioRef />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route exact path="/confermaordine" element={<ConfirmationOrder />} />
            <Route
              exact
              path="/print"
              element={
                <Stampa
                  handleFronteRetro={handleFronteRetro}
                  handlepath={handlepath}
                  handlepathfrontespizio={handlepathfrontespizio}
                  totale={totale}
                  numpagine={numpagine}
                  paginebn={paginebn}
                  paginecolori={paginecolori}
                  clickable={clickable}
                  addvisitableroute={addvisitableroute}
                />
              }
            />
            <Route exact path="/print/document" element={<Dropdownmenu />} />
            <Route
              exact
              path="/print/rilegature"
              element={
                <Rilegature
                  handleFronteRetro={handleFronteRetro}
                  temp={materialiarray}
                  go={go}
                  materialiarray={materialiePrezzi}
                  buttonvisitable={buttonvisitable}
                  ordini={copie}
                  showpages={showpages}
                  menocopia={menocopia}
                  piucopia={piucopia}
                  removecopia={removecopia}
                  resetCopie = {resetCopie}
                  handlecopie={handlecopie}
                  handlematerialieprezzi={handlematerialieprezzi}
                  totale={totale}
                  handletotale={handletotale}
                  handletotaleconcopie={handletotaleconcopie}
                  numpagine={numpagine}
                  paginebn={paginebn}
                  paginecolori={paginecolori}
                  pricepaginebn={pricepaginebn}
                  pricepaginecolori={pricepaginecolori}
                  clickable={clickable3}
                  arrayvisitable={arrayvisitable}
                  addvisitableroute={addvisitableroute}
                  grammatura={grammatura}
                />
              }
            />
            <Route
              exact
              path="/print/colore"
              element={
                <Colore
                  arraycolorpages={arraycolorpages}
                  ordini={copie}
                  loadingfinished={loadingfinished}
                  showpages={showpages}
                  handleloading={handleloading}
                  pathcolore={pathcolore}
                  pathfrontespiziocolore={pathfrontespiziocolore}
                  handlenumpagine={handlenumpagine}
                  handlefinishloading={handlefinishloading}
                  handleallpaginebn={handleallpaginebn}
                  handleallpaginecolore={handleallpaginecolore}
                  handlegrammatura={handlegrammatura}
                  handlepaginebn = {handlepaginebn}
                  handlepaginecolore = {handlepaginecolore}
                  grammatura={grammatura}
                  handletotale={handletotale}
                  handlecolorepagine={handlecolorepagine}
                  handlearraycolor={handlearraycolor}
                  totale={totale}
                  numpagine={numpagine}
                  pricepaginebn={pricepaginebn}
                  pricepaginecolori={pricepaginecolori}
                  paginebn={paginebn}
                  paginecolori={paginecolori}
                  clickable={clickable2}
                  arrayvisitable={arrayvisitable}
                  addvisitableroute={addvisitableroute}
                />
              }
            />
            <Route
              exact
              path="/print/dati"
              element={
                <Dati
                  ordini={copie}
                  sendinfo={sendinfo}
                  clickable={clickable4}
                  arrayvisitable={arrayvisitable}
                  addvisitableroute={addvisitableroute}
                  updateSpedizione={updateSpedizione}
                  spedizione={spedizione}
                  prezzoSpedizione={prezzoSpedizione}
                  totaleConSpedizione={totaleConSpedizione}
                  arraycolor={arraycolorpages}
                />
              }
            />
            <Route
              exact
              path="/print/pagamento"
              element={
                <Pagamento
                  totale={totale}
                  fronteretro={fronteRetro}
                  consegna={consegna}
                  ordini={copie}
                  dati={dati}
                  clickable={clickable5}
                  arrayvisitable={arrayvisitable}
                  addvisitableroute={addvisitableroute}
                  updateSpedizione={updateSpedizione}
                  spedizione={spedizione}
                  prezzoSpedizione={prezzoSpedizione}
                  totaleConSpedizione={totaleConSpedizione}
                  arraycolor={arraycolorpages}
                />
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
