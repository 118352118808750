import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../API";
import { renderToString } from "react-dom/server";
import ConfirmationOrder from "./ConfirmationOrder";

function PayPal(props) {
  const paypal = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    let files = [];
    let ordinimail = [];

    props.ordini.forEach((e) => {
      let ordine = {
        Rilegatura: e.type.rilegatura,
        Materiale: e.type.materiale,
        Copertina: e.type.copertina,
        Scritta: e.type.scritta,
        Grammatura: e.type.grammatura,
        Numero_copie: e.value,
      };
      ordinimail.push(ordine);
    });

    let colorpagesarray = props.arraycolor.filter((pagina) => pagina.color).map((c) => c.id + 1).join(",");

    let tablemailconfirm = renderToString(
      <ConfirmationOrder
              cliente = {true}
              totale={props.totale}
              ordini={props.ordini}
              fronteretro={props.fronteretro}
              consegna={props.consegna}
              arraycolor={props.arraycolor}
              colorpagesarray = {colorpagesarray}
              dati={props.dati}
              prezzoSpedizione ={ props.prezzoSpedizione}
              corriere={props.corriere}
    />
    )

    let tablemail = renderToString(
      <ConfirmationOrder
        cliente = {false}
        totale={props.totale}
                    ordini={props.ordini}
                    fronteretro={props.fronteretro}
                    consegna={props.consegna}
                    arraycolor={props.arraycolor}
                    colorpagesarray = {colorpagesarray}
                    dati={props.dati}
                    prezzoSpedizione ={ props.prezzoSpedizione}
                    corriere={props.corriere}
      />
    );

    let tesi = window.localStorage.getItem("pathtesi");
    let frontespizio = window.localStorage.getItem("pathfrontespizio");

    if (tesi) files.push(tesi);
    if (frontespizio) files.push(frontespizio);

    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Copia",
                amount: {
                  currency_code: "EUR",
                  value: props.totale,
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(function (details) {
            API.SendEmail(
              "legatoriaecoduemila@gmail.com",
              "Nuovo ordine - Stampa tesi ",
              JSON.stringify(props.ordini),
              tablemail,
              files
            ).then()
            .catch((err) => console.log(err));
  
            API.SendClientEmail(
              `${props.dati.Email}`,
              "Conferma ordine",
              "",
              tablemailconfirm,
            )
              .then(
                console.log("Success"),
                navigate("/ordersuccess"))
              .catch((err) => console.log(err));
          });
        },
        onError: (err) => {
          navigate("/orderfailed");
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}> </div>
    </div>
  );
}

export default PayPal;
