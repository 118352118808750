import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Css folder/Upload.css";
import { Link } from "react-router-dom";
import { CheckCircle, ChevronRight } from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import API from "../API";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Upload(props) {
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [FrontespiziopdfFile, setFrontespizioPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");
  const [FrontespiziopdfFileError, setFrontespizioPdfFileError] = useState("");
  const [path, setPath] = useState("");
  const [pathfrontespizio, setPathFrontespizio] = useState("");
  const fileType = ["application/pdf"];
  const timeout = 500000;
  const [documentLoaded, setDocumentLoaded] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let path = window.localStorage.getItem("pathtesi");
    let pathfrontespizio = window.localStorage.getItem("pathfrontespizio");

    if (path) {
      API.CheckFile(path).then((result) => {
        if (result) {
          var pathlocalstorage = JSON.parse(
            window.localStorage.getItem("isSelected")
          );
          setIsSelected(pathlocalstorage);
          setPath(path);
          props.handlepath(path);
          props.addvisitableroute("rilegature");
        } else {
          window.localStorage.clear();
        }
      });
    } else {
      window.localStorage.clear();
    }

    if (pathfrontespizio) {
      var pathlocalstorage = JSON.parse(
        window.localStorage.getItem("isSelected2")
      );
      setIsSelected2(pathlocalstorage);
      setPathFrontespizio(pathfrontespizio);
      props.handlepathfrontespizio(pathfrontespizio);
    } else {
      setPathFrontespizio(window.localStorage.getItem("pathtesi"));
    }
  }, []);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleloading = () => {
    setLoading(true);
  };

  function checkpathfrontespizio(selectedFile, correctName) {
    if (pathfrontespizio == null || isSelected) {
      window.localStorage.setItem("isSelected2", true);
      setPathFrontespizio(correctName);
    }
  }

  function checkpathfrontespiziopresent(oldfile) {
    if (pathfrontespizio == null) {
      window.localStorage.removeItem("pathfrontespizio");
    }
    if (pathfrontespizio == oldfile) {
      window.localStorage.removeItem("pathfrontespizio");
      setPathFrontespizio(null);
    }
  }
  function revertpathfrontespizio() {
    if (window.localStorage.getItem("pathtesi")) {
      setPathFrontespizio(window.localStorage.getItem("pathtesi"));
      window.localStorage.removeItem("pathfrontespizio");
      window.localStorage.setItem("isSelected2", false);
    } else {
      window.localStorage.removeItem("pathfrontespizio");
      window.localStorage.setItem("isSelected2", false);
      setPathFrontespizio("");
    }
  }

  const handleSubmit = (event) => {
    let randomstring = makeid(5);
    let selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("random", randomstring);
      let oldfile = window.localStorage.getItem("pathtesi");

      if (selectedFile && fileType.includes(selectedFile.type)) {
        
        if (oldfile) {

          API.DeleteOldFile(oldfile).then(
            API.UploadFile(formData).then((prova)=>
            console.log(prova)
            )
              .catch((err) => console.log(err))
              .finally(()=> 
              caricaFrontespizio(randomstring,selectedFile)
              )
          );
        } else {
          API.UploadFile(formData).then((prova)=>
          console.log(prova)
          )
            .catch((err) => console.log(err))
            .finally(()=> 
            caricaFrontespizio(randomstring,selectedFile)
            )
        }
      } else {
        setPdfFile(null);
        alert("Carica un file in formato Pdf");
      }
    }
  };


  const caricaFrontespizio = (randomstring,selectedFile) => {
            let correctName =
            randomstring + selectedFile.name.split(" ").join("_");
            window.localStorage.setItem("pathtesi", correctName);
            window.localStorage.setItem("isSelected", true);
            window.localStorage.removeItem("finishloading");
            setPdfFileError("");
            checkpathfrontespizio(selectedFile, correctName);
            setPath(selectedFile);
            props.addvisitableroute("rilegature");
            props.handlepath(correctName);
            setIsSelected(true);

  }


  const handleSubmit2 = (event) => {
    let correctName;
    let randomstring = makeid(5);
    let selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("random", randomstring);
      let oldfile = window.localStorage.getItem("pathfrontespizio");

      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader;

        if (oldfile) {
          API.DeleteOldFile(oldfile).then(
            API.UploadFile(formData)
              .then(
                (correctName =
                  randomstring + selectedFile.name.split(" ").join("_")),
                window.localStorage.setItem("pathfrontespizio", correctName),
                window.localStorage.setItem("isSelected2", true),
                setFrontespizioPdfFileError(""),
                setPathFrontespizio(correctName),
                props.handlepathfrontespizio(correctName),
                setIsSelected2(true)
              )
              .then(
                setTimeout(function () {
                  API.DeleteOldFile(correctName).then(
                    window.localStorage.setItem("pathfrontespizio", ""),
                    window.localStorage
                      .setItem("frontespizioisSelected", false)
                      .catch((err) => console.log(err))
                  );
                }, timeout)
              )
              .catch((err) => console.log(err))
          );
        } else {
          API.UploadFile(formData)
            .then(
              correctName =
                randomstring + selectedFile.name.split(" ").join("_"),
              window.localStorage.setItem("pathfrontespizio", correctName),
              window.localStorage.setItem("isSelected2", true),
              setFrontespizioPdfFileError(""),
              setPathFrontespizio(correctName),
              props.handlepathfrontespizio(correctName),
              setIsSelected2(true)
            )
            .catch((err) => console.log(err));
        }
      } else {
        setFrontespizioPdfFile(null);
        setFrontespizioPdfFileError("Carica un file in formato Pdf");
      }
    }
  };

  const removefile = () => {
    let oldfile = window.localStorage.getItem("pathtesi");
    API.DeleteOldFile(oldfile)
      .then(
        checkpathfrontespiziopresent(oldfile),
        window.localStorage.removeItem("pathtesi", oldfile),
        window.localStorage.setItem("isSelected", false),
        window.localStorage.removeItem("finishloading"),
        window.localStorage.removeItem("numpagine"),
        window.localStorage.removeItem("arraycolor"),
        window.localStorage.removeItem("paginebn"),
        window.localStorage.removeItem("paginecolori"),
        setIsSelected(false),
        setPath("")
      )
      .catch((err) => console.log(err));
  };

  const removefrontespizio = () => {
    let oldfile = window.localStorage.getItem("pathfrontespizio");
    API.DeleteOldFile(oldfile)
      .then(revertpathfrontespizio(), setIsSelected2(false))
      .catch((err) => console.log(err));
  };

  function onDocumentLoadSuccess({ numPages }) {
    setLoading(false);
    setNumPages(numPages);
    setDocumentLoaded("Documento caricato con successo!");
  }

  return (
    <div className="Firstpagecontainer">
      <Container>
        <Row className="rowdocument">
          <div className="containerconfig">
            <Row>
              <div className="title">1. Caricamento file</div>
            </Row>
            <Row>
            <Col sm={12} className="coltesi">
              <div className="tesisection">
                <div className="headtitle"> Carica il tuo file in formato PDF per mantenere una corretta formattazione in fase di stampa!
                <br/>
                <br/>
              <div className="small">
                <div className="bold text-m">Nota bene:</div>
                <div className="text-s">
                  Se vuoi caricare un frontespizio diverso da stampare sulla <br/>
                 copertina esterna, non preoccuparti, lo puoi fare al prossimo step.
                 </div>
              </div>

                </div>
                <div className="divinput">
                  {!isSelected && (<form>
                    <label htmlFor="file1" className="button mt-m mw-xl">
                    <FontAwesomeIcon icon={faArrowUpFromBracket}/>
                    <div>
                        Carica il file PDF
                        
                     </div>  
                    </label>
                    <input
                      id="file1"
                      type="file"
                      accept="application/pdf"
                      name="sampleFile"
                      className="inputdiv"
                      multiple="multiple"
                      onChange={handleSubmit}
                    />
                  </form>)}
                  {isSelected && (
                     <div className="button mt-l mw-xl" onClick={() => removefile()}>
                       {" "}
                       Rimuovi{" "}
                     </div>
                   )}
                 
                </div>
                {isSelected ? (
                  <div className="contdoc2">
                    {window.localStorage.getItem("isSelected") ? (
                      <></>
                    ) : (
                      <Document
                        className="document1"
                        file={path}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={handleloading}
                      />
                    )}
                   

                    {window.localStorage.getItem("isSelected") ? (
                      <div className="alertmessage">
                       <div> <p> Documento caricato con successo</p> </div>
                       <div className="iconcircle"> <CheckCircle color={"green"} size={20}></CheckCircle> </div>
                      {/*<div className="pointer" onClick= {()=> window.location.reload()}>
                        <ArrowClockwise size={25} color={"#b01b40"}/>
                    </div>*/}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                  <p>Seleziona un file in formato PDF</p>
                  <p>Se il file è di grandi dimensioni potrebbe volerci qualche minuto per il caricamento.</p>
                  </>

                )}
              </div>
              <div className="frontespiziosection">
                <div className="text-l">Carica copertina (facoltativo)</div>
                <br/>
                <div className="divquestion">
                  <div className="headtitle">
                    {" "}
                    Se la copertina è gia presente nel file della tesi clicca su <b>Prosegui</b> <br/>altrimenti caricala in <b>formato PDF </b>
                    cliccando il bottone sottostante{" "}
                  </div>
                </div>
                <div className="divinput">
                  <div>
                    <label htmlFor="file2" className="button mw-xl">
                    <div style={{marginLeft:"5px"}}><FontAwesomeIcon icon={faArrowUpFromBracket}/></div>
                     <div style={{marginLeft:"10px"}}>{isSelected2
                        ? "Sostituisci la copertina"
                        : "Carica la copertina"}
                        
                     </div>  
                    </label>
                    <input
                      id="file2"
                      type="file"
                      accept="application/pdf"
                      name="file2"
                      className="inputdiv"
                      onChange={handleSubmit2}
                    />
                  </div>
                  {isSelected2 && (
                     <div className="button abled" onClick={() => removefrontespizio()}>
                       {" "}
                       Rimuovi copertina{" "}
                     </div>
                   )}
                </div>
                {isSelected2 ? (
                  <div className="contdoc2">
                    <div> Documento caricato </div>
                    <div className="iconcircle"> <CheckCircle color={"green"} size={20}></CheckCircle> </div>
                  </div>
                ) : (
                  <p> </p>
                )}
              </div>
            </Col>
            {/*       
            <Col
              sm={0}
              className={
                isSelected || isSelected2
                  ? "colfrontespizio"
                  : "colfrontespiziopage inactive"
              }
            >
              <div className="headtitle"> Il tuo frontespizio </div>
              {pathfrontespizio && (
                <Document className="document1" file={"../" + pathfrontespizio} onLoadError={console.error}>
                  <div className="page">
                    <Page pageNumber={1}></Page>
                  </div>
                </Document>
              )}
            </Col>
            */}
          </Row>  
          </div>
        </Row>
      </Container>
      <div className="divbutton">

                <Link
                  id="linkactive"
                  to="/print/colore"
                  className={isSelected ? "flexible btninput mw-m linkactive" : "flexible disabled button mw-m linkdisabled"}
                >
                  {" "}
                  Prosegui{" "}
                  <ChevronRight size={12} color={isSelected ? "white" : "grey"}/>
                </Link>   
          </div>
    </div>
  );
}

export default Upload;
