import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

function HomePage() {
  return (
    <div className="main-container homepage">
      <div className="titoli center mb-s"> 
        <h1 className="text-white fw-600 text-xxl" style={{ marginBottom: "24px" }}>Stampa da noi la tua tesi di laurea!</h1>
        <h2 className="text-white text-xl">Devi solo caricare il file e scegliere il materiale della copertina, al resto penseremo tutto noi.</h2>
      </div>
      <div className="flexible mb-m flexmobile">
       <Link className="btnsecondary mr-l px-4" to="/preventivo">
          {" "}
          Richiedi preventivo{" "}
        </Link>
        <Link className="btninput linkactive ml-l px-4" to="/print">
          {" "}
          Stampa subito la tua tesi{" "}
        </Link>
        </div>
    </div>
  );
}

export default HomePage;
