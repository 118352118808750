import React, { useEffect, useState } from "react";
import "./Css folder/Banner.css";
import API from "../API";
export default function Banner() {

    const [orari,setOrari] = useState("");

    useEffect(() => {

        API.GetOrari().then((data)=> {
            setOrari(data);
        })
    })

return (
    <div className="banner hidemobile">
        <div className="content">
            <div className="left">
                <div className="banner_icon margin_banner">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_515_1280)">
                        <path d="M14.5917 11.5085L12.4984 9.41519C11.7508 8.66759 10.4799 8.96666 10.1809 9.93852C9.95659 10.6114 9.20898 10.9852 8.53613 10.8356C7.04092 10.4618 5.02239 8.51807 4.64859 6.9481C4.42431 6.27522 4.87287 5.52762 5.54571 5.30336C6.5176 5.00432 6.81664 3.73339 6.06904 2.98579L3.97574 0.892493C3.37766 0.369169 2.48053 0.369169 1.95721 0.892493L0.536758 2.31294C-0.883692 3.80815 0.68628 7.77046 4.20002 11.2842C7.71377 14.798 11.6761 16.4427 13.1713 14.9475L14.5917 13.527C15.1151 12.9289 15.1151 12.0318 14.5917 11.5085Z" fill="black"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_515_1280">
                        <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                </svg>
                </div>

                <div className="margin_banner">
                   011 815 9250 
                </div>

                <div className="margin_banner">
                    {orari}
                </div>
            </div>

            <div className="right">
                <div className="banner_icon truck margin_banner">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_516_1301)">
                    <path d="M17.1875 7.81252H21.3477C21.5033 7.8115 21.6556 7.8576 21.7846 7.94474C21.9136 8.03189 22.0132 8.15601 22.0703 8.3008L23.4375 11.7188" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.5625 14.0625H17.1875" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.3594 21.0938C19.6538 21.0938 20.7031 20.0444 20.7031 18.75C20.7031 17.4556 19.6538 16.4062 18.3594 16.4062C17.065 16.4062 16.0156 17.4556 16.0156 18.75C16.0156 20.0444 17.065 21.0938 18.3594 21.0938Z" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                    <path d="M6.64062 21.0938C7.93504 21.0938 8.98438 20.0444 8.98438 18.75C8.98438 17.4556 7.93504 16.4062 6.64062 16.4062C5.34621 16.4062 4.29688 17.4556 4.29688 18.75C4.29688 20.0444 5.34621 21.0938 6.64062 21.0938Z" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                    <path d="M16.0156 18.75H8.98438" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.29688 18.75H2.34375C2.13655 18.75 1.93784 18.6677 1.79132 18.5212C1.64481 18.3747 1.5625 18.176 1.5625 17.9688V7.03125C1.5625 6.82405 1.64481 6.62534 1.79132 6.47882C1.93784 6.33231 2.13655 6.25 2.34375 6.25H17.1875V16.7187" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.1875 11.7188H23.4375V17.9688C23.4375 18.176 23.3552 18.3747 23.2087 18.5212C23.0622 18.6677 22.8635 18.75 22.6562 18.75H20.7031" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_516_1301">
                    <rect width="25" height="25" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                </div>
                <div className="margin_banner">
                    Le tesi sono pronte entro 24h dall'ordine o in 4h con l'opzione Emergenza
                </div>
            </div>
        </div>
    </div>
)
}