import React from "react";
import { Link } from "react-router-dom";
import "./Css folder/Footer.css";
import PaypalBadge from "./Home/PaypalBadge";

function Footer() {
  return <div className="footer">

<div className="contain">
  <div className="col hidemobile">
  <h1><img className="image" width={"170"} height={"30"} src={"../../logo.png"}/></h1>
    <ul>
      <li>Copyrights © 2023</li>
      <li>Tutti i diritti riservati</li>
    </ul>
  </div>
<div className="col">
    <h1>Pagine e servizi</h1>
    <ul>
      <li><Link className="link" to={"/materiali"}>Rilegatura tesi</Link></li>
      <li><Link className="link" to={"/servizi"}>Altri servizi</Link></li>
      <li><Link className="link" to={"/preventivo"}>Richiedi preventivo </Link></li>
    </ul>
  </div>
  <div className="col">
    <h1>Link utili</h1>
    <ul>
      <li><Link className="link" to={"/faq"}>FAQ</Link></li>
      <li><Link className="link" to={"/help"}>Contattaci</Link></li>
      <li><Link className="link" to={"https://www.iubenda.com/privacy-policy/31546151"}>Privacy Policy</Link></li>
    </ul>
  </div>
  <div className="col paypalbadges">
   <PaypalBadge/>
  </div>
  </div>
  </div>;
}

export default Footer;
