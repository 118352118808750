import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import "./Css folder/Rilegature.css";
import Dropdownmenu from "./Dropdownmenu";
import Totale from "./Totale";
import API from "../API";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
function Rilegature(props) {

  const [selectedmaterial, setSelectedMaterial] = useState("Ecopelle");
  const [colorcopertina, setColorCopertina] = useState("Arancione");
  const [colorcopertina_src, setColorCopertinaSrc] = useState("arancione.png");
  const [colorfrontespizio, setColorFrontespizio] = useState("Argento");
  const [colorfrontespizio_src, setColorFrontespizioSrc] =
    useState("argento.png");
  const [cartoncino, setCartoncino] = useState(false);
  const [rilegatura, setRilegatura] = useState("Rigida");
  const [grammatura, setGrammatura] = useState("80g");
  const [materialiarray, setMaterialiArray] = useState([]);
  const [materialiePrezzi, setMaterialiePrezzi] = useState([]);
  const [materiali_rigida,setMaterialiRigida] = useState([]);
  const [miniaturearray, setMiniatureArray] = useState([]);
  const [nomimateriali, setNomiMateriali] = useState([]);
  const [scritte, setScritte] = useState([]);
  const [fronteRetro,setFronteRetro] = useState(false);
  const [go, setGo] = useState(false);
  const [update, setUpdate] = useState(false);
  const testorigida = 
  " E’ la rilegatura classica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartone rigido rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testomorbida = "E’ la rilegatura elegante, ma più economica utilizzata per le Tesi di Laurea. Copertina, Dorso e Retro sono in cartoncino rivestito dal materiale scelto. L’incisione è superficiale e viene replicato il tuo frontespizio. puoi trovare degli esempi.";
  const testocartoncino = "E’ la rilegatura più economica utilizzata per le Tesi di Laurea. La copertina è la fotocopia del frontespizio su un cartoncino di colore Rosso, Granata, Blu o Verde. Non vi è alcuna incisione. puoi trovare degli esempi.";


  var prezzocarta80g = 0.05;
  var prezzocarta120g = 0.08;
  var prezzocartariciclata = 0.06;
  var prezzocartoncino = 5;
  var prezzomorbida = 12;

  const navigate = useNavigate();

  useEffect(() => {
    if (props.ordini.length > 0) {
      let tot = 0;

      props.ordini.forEach((copia) => {
        tot = parseFloat(parseFloat(tot) + parseFloat(copia.prezzo));
      });
      tot = parseFloat(parseFloat(tot)).toFixed(2);
      props.handletotaleconcopie(tot);
    } else {
      let tot = 0;
      //tot = parseFloat(parseFloat(props.pricepaginebn) + parseFloat(props.pricepaginecolori)).toFixed(2);
      props.handletotaleconcopie(tot);
    }

    API.GetMaterials().then((materiali) => {
      setNomiMateriali(materiali);
    });

    API.GetAllMaterials().then((allmaterials) => {
      setMaterialiArray((materialiarray) => [
        ...materialiarray,
        ...allmaterials,
      ]);

      API.GetAllScritte().then((allscritte) => {
        setScritte(allscritte);
        setGo(true);

        API.GetPrezzi().then((data) => {
          let array = data.split(/\r?\n/);
          let materiale_prezzi = array.map((el) => {
            let obj = { materiale: "", prezzo: "" };
            let splitted = el.split(" ");

            obj.materiale = splitted[0];
            obj.prezzo = splitted[1];

            return obj;
          });
          setMaterialiePrezzi(materiale_prezzi);
          props.handlematerialieprezzi(materiale_prezzi);
          const materialiRigida = materiale_prezzi.filter((el) => el.materiale !== "Tela" && el.materiale !== "Opera" && el.materiale !== "Quarzo" && el.materiale !== "Algora");
          setMaterialiRigida(materialiRigida);
        });
      });

      API.GetAllMiniature().then((allminiature) => {
        setMiniatureArray((miniaturearray) => [
          ...miniaturearray,
          ...allminiature,
        ]);
      });
    });
  }, []);

  useEffect(() => {

    if(!props.paginebn && !props.paginecolori) {
      navigate("/print/colore");
    }

    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {

    if (materialiarray.length > 0) {
      let colorecopertina = materialiarray.find(function (element) {
        return element.materiale == selectedmaterial;
      });

      setColorCopertina(colorecopertina.nome);
    }
  }, [selectedmaterial]);

  useEffect(() => {
    //magnify("imgcopertina", 2);
  }, [colorcopertina, selectedmaterial]);

  const updateValue = ({ target }) => {
    setSelectedMaterial(target.value);

    let copertina = materialiarray.find(function (element) {
      return element.materiale == target.value;
    });

    setColorCopertina(copertina.nome);
    setColorCopertinaSrc(copertina.immagine);
  };

  const updateValueRilegatura = ({ target }) => {
    handleRilegatura(target.value);
  };


  const scrollingElement = document.scrollingElement || document.body;

  const scrollToBottom = () => {
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };

  function handlecopie() {
    const newcopia = {
      rilegatura: rilegatura,
      materiale: selectedmaterial,
      copertina: colorcopertina,
      copertina_src: colorcopertina_src,
      scritta: colorfrontespizio,
      scritta_src: colorfrontespizio_src,
      grammatura: grammatura,
      impaginazione: fronteRetro ? "Fronteretro" : "Solo fronte"
    };
    scrollToBottom();
    console.log(newcopia);
    props.handlecopie(newcopia);
  }

  function menocopia(index) {
    props.menocopia(index);
  }

  function piucopia(index) {
    props.piucopia(index);
  }

  function removecopia(index) {
    props.removecopia(index);
  }

  function resetCopie () {
    props.resetCopie()
  }

  const handlegrammatura = (grammatura) => {
    setGrammatura(grammatura);
    //props.handlegrammatura(grammatura);
  };


  const handleFronteRetro = (value) => {

    setFronteRetro(value);
    props.handleFronteRetro(value);

  }

  function handleCopertina(nome, immagine) {
    setColorCopertina(nome);
    setColorCopertinaSrc(immagine);
  }

  function handleFrontespizio(nome, immagine) {
    setColorFrontespizio(nome);
    setColorFrontespizioSrc(immagine);
  }

  const handleRilegatura = (_rilegatura) => {
    if (_rilegatura == "Cartoncino") {
      setSelectedMaterial("Ecopelle");
      setColorCopertina("Blu chiaro");
      setColorCopertinaSrc("../../miniature/Ecopelle/blu_chiaro.png");
      setCartoncino(true);
      setColorFrontespizio("Nero");
      setColorFrontespizioSrc("../../scritteCopertina/nero.png");
    } else if (rilegatura == "Cartoncino") {
      setCartoncino(false);
      let copertina = materialiarray.find(function (element) {
        return element.materiale == "Ecopelle";
      });

      setColorCopertina(copertina.nome);
      setColorCopertinaSrc(copertina.immagine);
    }

    setRilegatura(_rilegatura);
  };


  //if (props.arrayvisitable.includes("rilegature"))
  return (
    <div className="containerstampa" style={{ width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <div className="containerrow">
          <div className="el1">
            <Dropdownmenu selected="rilegature" clickable={props.clickable} />
          </div>
          <div className="divcontrilegature">
            <div className="containerconfig">
              <div className="el2 rilegature">
                <div className="Secondpagecontainer">
                  <Row>
                    <div className="title">3. Imposta Rilegature</div>
                  </Row>
                  <div>
                    
                    <div className="container1">
                    
                      <div className="selections">
                      <div className="showmobile flex-column rilegature-item">
                    <div className="itemtitle">
                            {" "}
                            Anteprima copertina{" "}
                          </div>
                    <div className="divcopertinemobile">
                        <img
                          id="imgcopertina"
                          className="miniatura"
                          src={
                            "../../mobile-miniature/" +
                            selectedmaterial +
                            "/" +
                            colorcopertina_src
                          }
                        />

                        <img
                          id="imgscritta"
                          className="scrittaminiatura"
                          src={
                            "../../scritteMobile/" + colorfrontespizio_src
                          }
                        />
                      </div>
                    </div>
                        <div className="rilegature-item tiporilegatura">
                          <div className="itemtitle">
                            {" "}
                            Scegli la tipologia di rilegatura{" "}
                          </div>
                          <div className="divselect">
                            <select
                              className="formselect"
                              label="Default select example"
                              onChange={updateValueRilegatura}
                            >
                              <option value={"Rigida"}>Rigida (a partire da 18€)</option>
                              <option value={"Morbida"}>Morbida ({prezzomorbida}€)</option>
                              <option value={"Cartoncino"}>
                                Cartoncino ({prezzocartoncino}€)
                              </option>
                            </select>
                          </div>
                          <div style={{ width: "80%",paddingTop: "10px", fontSize: "13px"}}>
                        {rilegatura == "Rigida" ? testorigida : rilegatura == "Morbida" ? testomorbida : testocartoncino}
                      </div>
                        </div>

                        {rilegatura == "Rigida" && (
                          <div className="rilegature-item tipomateriale">
                            <div className="itemtitle">
                              {" "}
                              Scegli la tipologia di materiale{" "}
                            </div>
                            <div className="divselect">
                              <select
                                className="formselect"
                                label="Default select example"
                                onChange={updateValue}
                              >
                                {materialiePrezzi.map((element, i) => (
                                  <option
                                    defaultValue
                                    value={element.materiale}
                                  >
                                    {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo}€`}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {rilegatura == "Morbida" && (
                          <div className="rilegature-item tipomateriale">
                            <div className="itemtitle">
                              {" "}
                              Scegli la tipologia di materiale{" "}
                            </div>
                            <div className="divselect">
                              <select
                                className="formselect"
                                label="Default select example"
                                onChange={updateValue}
                              >
                                {materiali_rigida.map((element, i) => (
                                  <option
                                    defaultValue
                                    value={element.materiale}
                                  >
                                    {element.materiale} {rilegatura == "Morbida" ? "" : `${element.prezzo}€`}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="rilegature-item colorefrontespizio">
                          <div className="itemtitle">
                            Scegli il colore di stampa del frontespizio{" "}
                          </div>
                          <div className="divcolorifrontespizio">
                            {go && rilegatura != "Cartoncino" ? (
                              scritte.map((element, i) => (
                                <div key={i} className="divcolorecopertina">
                                  <div className="colorecopertina">
                                    {" "}
                                    <img
                                      className={
                                        colorfrontespizio == element.nome
                                          ? "imgcolori selected"
                                          : "imgcolori"
                                      }
                                      onClick={() =>
                                        handleFrontespizio(
                                          element.nome,
                                          element.immagine
                                        )
                                      }
                                      src={"../../scritte/" + element.immagine}
                                    ></img>{" "}
                                  </div>
                                  <div style={{ alignSelf: "center" }}>
                                    {" "}
                                    {element.nome}{" "}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="divcolorecopertina">
                                <div className="colorecopertina">
                                  {" "}
                                  <img
                                    className={"imgcolori selected"}
                                    src={"../../scritte/nero.png"}
                                  ></img>{" "}
                                </div>
                                <div style={{ alignSelf: "center" }}>
                                  {" "}
                                  Nero{" "}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="rilegature-item coloremateriale">
                          <div className="itemtitle">
                            Scegli il colore della copertina
                          </div>
                          <div className="divcoloricopertina">
                            {!cartoncino
                              ? materialiarray.map(
                                  (element, i) =>
                                    element.materiale == selectedmaterial && (
                                      <div
                                        key={i}
                                        className="divcolorecopertina"
                                      >
                                        <div className="colorecopertina">
                                          {" "}
                                          <img
                                            className={
                                              colorcopertina === element.nome
                                                ? "imgcolori selected"
                                                : "imgcolori"
                                            }
                                            onClick={() =>
                                              handleCopertina(
                                                element.nome,
                                                element.immagine
                                              )
                                            }
                                            src={
                                              "../../materiali/" +
                                              element.materiale +
                                              "/" +
                                              element.immagine
                                            }
                                          ></img>{" "}
                                        </div>
                                        <div style={{ alignSelf: "center" }}>
                                          {" "}
                                          {element.nome}{" "}
                                        </div>
                                      </div>
                                    )
                                )
                              : materialiarray.map(
                                  (element, i) =>
                                    element.materiale == selectedmaterial &&
                                    (element.nome == "Rosso" ||
                                      element.nome == "Blu chiaro" ||
                                      element.nome == "Granata" ||
                                      element.nome == "Verde marmorizzato") && (
                                      <div
                                        key={i}
                                        className="divcolorecopertina"
                                      >
                                        <div className="colorecopertina">
                                          {" "}
                                          <img
                                            className={
                                              colorcopertina === element.nome
                                                ? "imgcolori selected"
                                                : "imgcolori"
                                            }
                                            onClick={() =>
                                              handleCopertina(
                                                element.nome,
                                                element.immagine
                                              )
                                            }
                                            src={
                                              "../../materiali/" +
                                              element.materiale +
                                              "/" +
                                              element.immagine
                                            }
                                          ></img>{" "}
                                        </div>
                                        <div style={{ alignSelf: "center" }}>
                                          {" "}
                                          {element.nome}{" "}
                                        </div>
                                      </div>
                                    )
                                )}
                          </div>
                        </div>
                      </div>
                      <div className="divcopertina rilegature">
                        <img
                          id="imgcopertina"
                          className="imganteprima"
                          src={
                            "../../miniature/" +
                            selectedmaterial +
                            "/" +
                            colorcopertina_src
                          }
                        />

                        <img
                          id="imgscritta"
                          className="imgscritta"
                          src={
                            "../../scritteCopertina/" + colorfrontespizio_src
                          }
                        />
                      </div>
                    </div>

                    <div className="rilegature-item tipocarta">
                      <div className="itemtitle">
                        {" "}
                        Scegli la grammatura delle pagine interne{" "}
                      </div>
                      <div className="tipocarta2">
                        <div className="inputradio">
                          <input
                            type="radio"
                            id="80g"
                            name="drone"
                            value="80g"
                            defaultChecked
                            onClick={() => handlegrammatura("80g")}
                          />
                          <div className="labelradio">
                            Standard 80g <b>{prezzocarta80g}€</b>
                          </div>
                        </div>

                        <div className="inputradio">
                          <input
                            type="radio"
                            id="120g"
                            name="drone"
                            value="120g"
                            onClick={() => handlegrammatura("120g")}
                          />
                          <div className="labelradio">
                            Standard 120g <b> {prezzocarta120g}€</b>
                          </div>
                        </div>

                        <div className="inputradio">
                          <input
                            type="radio"
                            id="riciclata"
                            name="drone"
                            value="riciclata"
                            onClick={() => handlegrammatura("riciclata")}
                          />
                          <div className="labelradio">
                            Riciclata 65g <b>{prezzocartariciclata}€</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divfronteretro rilegature-item">
                  <div className="itemtitle">Scegli il tipo di stampa:</div>
                  <div className="tipocarta flexible flex-column">
                    <div className="inputradio">
                      <input
                        type="radio"
                        id="fronte"
                        name="fronteretro"
                        value="true"
                        defaultChecked
                        onClick={() => handleFronteRetro(false)}
                      />
                      <div className="labelradio">
                        <div className="text-m">
                          SOLO FRONTE
                        </div>{" "}
                      </div>
                    </div>

                    <div className="inputradio">
                      <input
                        type="radio"
                        id="fronteretro"
                        name="fronteretro"
                        value="false"
                        onClick={() => handleFronteRetro(true)}
                      />
                      <div className="labelradio">
                      <div className="text-m">
                          FRONTE E RETRO
                        </div>{" "}
                      </div>
                    </div>
                  </div>
              </div>
                    <div className="rilegature-item">
                      <div className="itemtitle">
                        {" "}
                        Aggiungi una o più copie con la configurazione
                        selezionata{" "}
                      </div>
                      <div className="btninput" onClick={() => handlecopie()}>
                        {" "}
                        {props.ordini.length > 0
                          ? "Aggiungi copia al carrello"
                          : "Aggiungi copia al carrello "}{" "}
                      </div>
                    </div>
                    <div className="rilegature-item ordini">
                      <div className="itemtitle">Le tue copie</div>
                      {props.ordini.length != 0 ? (
                        <>
                          <div
                            className="hidemobile"
                            style={{
                              display: "table",
                              width: "100%",
                              borderSpacing: "10px",
                              zIndex: 3,
                            }}
                          >
                            <div className="tablecopie">
                              <th>Rilegatura</th>
                              <th> Materiale</th>
                              <th> Copertina </th>
                              <th> Scritta</th>
                              <th> Grammatura </th>
                              <th> Impaginazione </th>
                              <th> Quantità </th>
                            </div>
                            {Array(props.ordini.length)
                              .fill(1)
                              .map((_, i) => (
                                <tr key={i}>
                                  <td> {props.ordini[i].type.rilegatura} </td>
                                  <td> {props.ordini[i].type.materiale}</td>
                                  <td> {props.ordini[i].type.copertina}</td>
                                  <td> {props.ordini[i].type.scritta}</td>
                                  <td> {props.ordini[i].type.grammatura}</td>
                                  <td> {props.ordini[i].type.impaginazione}</td>
                                  <td>
                                    <div className="divrilegatura">
                                      <div
                                        className={
                                          "itemrilegatura rigida active"
                                        }
                                        style={{ width: "30px" }}
                                        onClick={() => menocopia(i)}
                                      >
                                        {" "}
                                        -{" "}
                                      </div>
                                      <div
                                        className={"itemrilegatura"}
                                        style={{ width: "40px" }}
                                      >
                                        {" "}
                                        {props.ordini[i].value}
                                      </div>
                                      <div
                                        className={
                                          "itemrilegatura cartoncino active"
                                        }
                                        style={{ width: "30px" }}
                                        onClick={() => piucopia(i)}
                                      >
                                        {" "}
                                        +
                                      </div>
                                    </div>
                                  </td>
                                  
                                </tr>
                              ))}
                          </div>
                          <div className="copiemobile showmobile">
                            {Array(props.ordini.length)
                              .fill(1)
                              .map((_, i) => (
                                <div className="d-flex mb-m">
                                <div
                                 className="contril"
                              >
                                <img
                                  id="imgcopertina"
                                  className="imganteprima"
                                  src={`../../miniature/${
                                    props.ordini[i].type.materiale != "Cartoncino"
                                      ? props.ordini[i].type.materiale
                                      : "Ecopelle"
                                  }/${props.ordini[i].type.copertina_src}`}
                                />
  
                                <img
                                  id="imgscritta"
                                  className="imgscritta"
                                  src={
                                    "../../scritteCopertina/" +
                                    props.ordini[i].type.scritta_src
                                  }
                                />
                                </div>
                                <div key={i}>
                                  <div> {props.ordini[i].type.rilegatura} </div>
                                  <div> {props.ordini[i].type.materiale}</div>
                                  <div> {props.ordini[i].type.copertina}</div>
                                  <div> {props.ordini[i].type.scritta}</div>
                                  <div> {props.ordini[i].type.grammatura}</div>
                                  <div> {props.ordini[i].type.impaginazione}</div>
                                  <div>
                                    <div className="divrilegatura">
                                      <div
                                        className={
                                          "itemrilegatura rigida active"
                                        }
                                        style={{ width: "30px" }}
                                        onClick={() => menocopia(i)}
                                      >
                                        {" "}
                                        -{" "}
                                      </div>
                                      <div
                                        className={"itemrilegatura morbida"}
                                        style={{ width: "40px" }}
                                      >
                                        {" "}
                                        {props.ordini[i].value}
                                      </div>
                                      <div
                                        className={
                                          "itemrilegatura cartoncino active"
                                        }
                                        style={{ width: "30px" }}
                                        onClick={() => piucopia(i)}
                                      >
                                        {" "}
                                        +
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              ))}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divbottoni">
              <Link className="button flexible mw-m" to="/print/colore">
                <ChevronLeft size={12} /> Indietro{" "}
              </Link>

              <Link
                className={
                  props.buttonvisitable
                    ? " btninput mw-m linkactive"
                    : "button disabled mw-m linkdisabled"
                }
                to="/print/dati"
              >
                {" "}
                Prosegui{" "}
                <ChevronRight
                  size={12}
                  color={props.buttonvisitable ? "white" : "grey"}
                />
              </Link>
            </div>
          </div>
          <div className="el4 totale">
            <div style={{ position: "sticky", top: "0px", width: "inherit" }}>
              <Totale
                copie={props.ordini}
                showpages={true}
                showcopie={true}
                update={update}
                tot={props.totale}
                numpagine={props.numpagine}
                paginebn={props.paginebn}
                paginecolori={props.paginecolori}
                prezzopaginebn={props.pricepaginebn}
                prezzopaginecolori={props.pricepaginecolori}
              />

              <div className="contbuttonandalert hidemobile">
                <Link
                  to="/print/dati"
                  className={
                    props.buttonvisitable
                      ? "btninput linkactive w-100"
                      : "button disabled linkdisabled"
                  }
                >
                  {" "}
                  Prosegui{" "}
                  <div className="arrowRight">
                    <ChevronRight
                      size={12}
                      color={props.buttonvisitable ? "white" : "grey"}
                    />
                  </div>
                </Link>
                <div className={props.buttonvisitable ? "btnsecondary W-100 mt-l" : "d-none"} onClick={() => resetCopie()}>Reset carrello</div>
                <div
                  className={!props.buttonvisitable ? "alertcopy" : "d-none"}
                  id="alertmessage"
                >
                  {" "}
                  Aggiungi almeno una copia per proseguire{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
  /*else {
    return <Navigate to="/print" />;
  }*/
}

export default Rilegature;
