import React from "react";
import Row from "react-bootstrap/Row";
import "./Css folder/Dropdownmenu.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Dropdownmenu(props) {


  return (
    <div className="contsel">
      <Row className="row_dropdown">
        <div
          className={
            props.selected == "document" ? "selmenu borderless" : "selmenu"
          }
        >
          <Link
            className={
              props.clickable.includes("document") && !props.loading
                ? "selmenu-item clickable"
                : "selmenu-item"
            }
            to="/print"
          >
            <div
              className={
                props.selected == "document" ? "item-active" : "item-inactive"
              }
            >
              1. <div className="hidemobile hidedescription">Caricamento file</div>
            </div>
          </Link>
        </div>
      </Row>
      <Row className="row_dropdown">
        <div
          className={
            props.selected == "colore" ? "selmenu borderless" : "selmenu"
          }
        >
          <Link
            className={
              props.clickable.includes("colore")
                ? "selmenu-item clickable"
                : "selmenu-item"
            }
            to="/print/colore"
          >
            <div
              className={
                props.selected == "colore" ? "item-active" : "item-inactive"
              }
            >
              
              2. <div className="hidemobile hidedescription">Selezione Colore{" "}</div>
            </div>
          </Link>
        </div>
      </Row>

      <Row className="row_dropdown">
        <div
          className={
            props.selected == "rilegature" ? "selmenu borderless" : "selmenu"
          }
        >
          <Link
            className={
              props.clickable.includes("rilegature")
                ? "selmenu-item clickable"
                : "selmenu-item"
            }
            to="/print/rilegature"
          >
            <div
              className={
                props.selected == "rilegature" ? "item-active" : "item-inactive"
              }
            >
              3. <div className="hidemobile hidedescription">Imposta rilegature</div>
            </div>
          </Link>
        </div>
      </Row>

      <Row className="row_dropdown">
        <div
          className={
            props.selected == "dati" ? "selmenu borderless" : "selmenu"
          }
        >
          <Link
            className={
              props.clickable.includes("dati")
                ? "selmenu-item clickable"
                : "selmenu-item"
            }
            to="/print/dati"
          >
            <div
              className={
                props.selected == "dati" ? "item-active" : "item-inactive"
              }
            >
              4. <div className="hidemobile hidedescription">Inserisci i dati</div>
            </div>
          </Link>
        </div>
      </Row>

      <Row className="row_dropdown">
        <div
          className={
            props.selected == "pagamento"
              ? "selmenu last borderless "
              : "selmenu last"
          }
        >
          <Link
            className={
              props.clickable.includes("pagamento")
                ? "selmenu-item clickable"
                : "selmenu-item "
            }
            to="/print/pagamento"
          >
            <div
              className={
                props.selected == "pagamento" ? "item-active" : "item-inactive"
              }
            >
              5. <div className="hidemobile hidedescription">Riepilogo e checkout</div>
            </div>
          </Link>
        </div>
      </Row>
    </div>
  );
}

export default Dropdownmenu;
