import React from "react";

function OrdineMail(props) {
  return (
    <div>
      {" "}
      <div>
      <b>Rilegatura</b> {props.ordine.Rilegatura} 
      </div>
      <div>
      <b>Materiale:</b> {props.ordine.Materiale}{" "}
      </div>
      <div>
      <b>Colore:</b> {props.ordine.Copertina}
      </div>
      <div>
      <b>Incisioni:</b> {props.ordine.Scritta}
      </div>
      <div>
      <b>Grammatura:</b> {props.ordine.Grammatura}
      </div>
      <div>
      <b>Impaginazione:</b> {props.ordine.Impaginazione}
      </div>
      <div>
      <b>Numero copie:</b>{props.ordine.Numero_copie}
      </div>
    </div>
  );
}

export default OrdineMail;
