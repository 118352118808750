import React, { useEffect, useState } from 'react'
import "./Css folder/Banner.css";
import API from "../API";

function Avvisi() {

    const [testoavvisi,setTestoAvvisi] = useState("");

    useEffect(() => {

        API.GetTestoAvvisi().then((data)=> {
            setTestoAvvisi(data);
        })
    })

  return (
    <div className= {testoavvisi ? "banneravvisi" : "banneravvisi d-none"}>
        <div style={{marginRight: "10px"}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="24 / notifications / alert-triangle">
            <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M22.4415 15.9532L15.4362 3.97762C14.7329 2.7584 13.4221 2.00166 12.0045 2C10.5867 1.99834 9.2754 2.75196 8.56336 3.97767L1.55543 15.9576C0.828751 17.1795 0.812312 18.7105 1.51618 19.9592C2.22063 21.2089 3.53966 21.9866 4.9744 21.9983L19.0123 21.9983C20.4619 21.9842 21.7784 21.2089 22.4828 19.9603C23.1863 18.7132 23.1706 17.1856 22.4415 15.9532ZM3.27808 16.9737L10.2912 4.98491C10.6464 4.37354 11.2978 3.99918 12.0021 4C12.7064 4.00083 13.3576 4.37673 13.7068 4.98222L20.7177 16.9672C21.0852 17.5885 21.0931 18.3533 20.7408 18.9777C20.3881 19.603 19.7287 19.9913 19.0025 19.9984L4.98253 19.9983C4.27193 19.9925 3.61123 19.6029 3.25844 18.9771C2.90604 18.3519 2.91427 17.5855 3.27808 16.9737ZM12.0003 17.9983C12.5528 17.9983 13.0007 17.5506 13.0007 16.9983C13.0007 16.4461 12.5528 15.9983 12.0003 15.9983C11.4479 15.9983 11 16.4461 11 16.9983C11 17.5506 11.4479 17.9983 12.0003 17.9983ZM13.0036 7.99835H11.003V14.9983H13.0036V7.99835Z" fill="black"/>
            </g>
        </svg>
        </div>
        <div>
            {testoavvisi}
        </div>
    </div>
  )
}

export default Avvisi