import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Css folder/Navbar.css";

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState("");
  const [showdrop, setShowDrop] = useState(false);

  const closeMobileMenu = (name) => {
    setClick(false);
    setSelect(name);
    setShowDrop(false);
  };

  const handleClick = () => setClick(!click);


  useEffect(() => {

    if(props.showdrop == "da chiudere")
    setShowDrop(false);

  },[props.showdrop])




  function handleShowDrop(name) {
    {/*setShowDrop(!showdrop);
    setSelect(name);
    props.handleshowdrop(!showdrop);
  */}
  }

  return (
    <>
      <div className="navbar1">
        <div className="navbar-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>

            {/*<li className="nav-item hidemobile" tabIndex="1">
              <div
                onClick={handleShowDrop}
                id="tesi_section"
                className={select != "tesi" ? "nav-links" : "nav-links-active"}
              >
                <span className="linksanimation">Tesi</span>{" "}
                <button id="btnsel" onClick={handleShowDrop} className="btnsel">
                  {" "}
                  {showdrop ? (
                    <ChevronCompactUp id="btnsel" className="freccia" />
                  ) : (
                    <ChevronCompactDown id="btnsel" className="freccia" />
                  )}{" "}
                </button>
                <div
                  className={showdrop ? "dropdowntesi" : "dropdowntesiinactive"}
                  id="dropdowntesi"
                >
                  <div className="dropdowntesilinks">
                    <Link
                      to="/preventivo"
                      onClick={() => {handleShowDrop("tesi"); closeMobileMenu("tesi")}}
                    >
                      {" "}
                      Preventivo rapido{" "}
                    </Link>
                  </div>
                  <div className="dropdowntesilinks">
                    <Link to="/materiali" onClick={() => {handleShowDrop("tesi"); closeMobileMenu("tesi")}}>
                      {" "}
                      I nostri materiali{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </li>
                  */}
          <div className="first-nav-items">
            <li className="nav-item" tabIndex="0">
            <Link
             className={select != "materiali" ? "nav-links" : "nav-links"}
             to="/materiali" 
             onClick={() => closeMobileMenu("materiali")}>
                      {" "}
                      I nostri materiali{" "}
            </Link>
            </li>
            <li className="nav-item" tabIndex="1">
            <Link
                      className={select != "preventivo" ? "nav-links" : "nav-links"}
                      to="/preventivo"
                      onClick={() => closeMobileMenu("preventivo")}
                    >
                      {" "}
                      Preventivo rapido{" "}
                    </Link>
            </li>
            
            <li className="nav-item" tabIndex="2">
              <Link
                to="/servizi"
                className={
                  select != "spedizione" ? "nav-links" : "nav-links"
                }
                onClick={() => closeMobileMenu("spedizione")}
              >
                <span className="linksanimation">Altri Servizi</span>
              </Link>
            </li>
            
            <li className="nav-item contatti" tabIndex="3">
              <Link
                to="/help"
                className={select != "aiuto" ? "nav-links" : "nav-links"}
                onClick={() => closeMobileMenu("aiuto")}
              >
                <span className="linksanimation"> Contatti </span>
              </Link>
            </li>

            </div>

            <li className="nav-item logo-item">
              <Link to="/" onClick={() => closeMobileMenu()}>
                <div className="divlogoimg">
                  <img src="../../logo.png"></img>
                </div>
              </Link>
            </li>

            {/*<li className="nav-item" tabIndex="3">
              <Link
                to="/preventivo"
                className={select != "aiuto" ? "nav-links" : "nav-links-active"}
                onClick={() => closeMobileMenu("aiuto")}
              >
                <span className="linksanimation"> Preventivo rapido </span>
              </Link>
            </li>
              */}

            <li className="nav-item last-item" tabIndex="4">
              <Link
                to="/print"
                className="last-nav-links"
                onClick={() => closeMobileMenu("print")}
              >
                Stampa la tua tesi
              </Link>
            </li>
          </ul>
          <li className="logomobile">
              <Link to="/" onClick={() => closeMobileMenu()}>
                <div className="divlogoimg">
                  <img src="../../logo.png"></img>
                </div>
              </Link>
            </li>
          <div className="box" onClick={handleClick}>
            <div className={click ? "btn active" : "btn not-active"}>
              <span className="btnanimation"></span>
              <span className="btnanimation"></span>
              <span className="btnanimation"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
