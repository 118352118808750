import React from 'react'
import "./Css folder/Help.css"
function Map() {


  return (
    <div>
      <img className="mapimg" src="../../google-maps.png"></img>
    </div>
  )
}

export default Map