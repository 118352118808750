import React from "react";
import { useState, useEffect } from "react";
import "./Css folder/Pagina.css";
function Immagine(props) {
  let i, j;
  const [color, setColor] = useState(true);
  const [showdivcolor, setShowDivColor] = useState(props.showdivcolor);

  useEffect(() => {
    let arraycolor = JSON.parse(window.localStorage.getItem("arraycolor"));
    let element = arraycolor.at(props.i);


    if (element.color) {
      setColor(true);
    } else {
      setColor(false);
    }

    //if (props.arraycolorpages.at(props.i).color) setColor(true);
    // else setColor(fals
  }, [props.clicked, window.localStorage.getItem("arraycolor")]);

  const handlecolorepagina = (index) => {
    props.handlecolorepagine(!color, index);
    setColor(!color);
  };

  return (
    <div
      className={showdivcolor ? "Pagediv" : "Pagedivinactive"}
      onClick={() => handlecolorepagina(props.i)}
    >
      <div
        className={color ? "circle" : "circlebn"}
        id={color ? "circle" : "circlebn"}
      ></div>
       <div className="badgePages">
        {props.i + 1}/{props.numPages}
      </div>
      <img
        className={color ? "page" : "page bn"}
        key={i}
        src={
          "../../fileimages/" +
          window.localStorage.getItem("pathtesi") +
          "__" +
          props.i +
          ".jpeg"
        }
      />
    </div>
  );
}

export default Immagine;
